import React, { Component } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Logout extends Component {
    render() {
        let auth = this.props.auth;
        setTimeout(function () {
            auth.logout();
        }, 1000);

        return (
            <div className="h-screen">
                <div className="container mx-auto pt-6 max-w-sm">
                    <div className="shadow p-8 bg-grey-lightest mt-6 text-center ">

                        <h3 className="mb-8">Logging you out</h3>

                        <p>
                            <span className="mr-2">Please hold on a moment, we're logging you out</span>
                            <FontAwesomeIcon icon="circle-notch" spin={true} />
                        </p>

                    </div>
                </div>
            </div>
        );
    }
}

export default Logout;
