export class Analyst {
    static nanosecondsToHuman(nanoseconds) {
        const ms = Math.round(nanoseconds / 1000000);
        if (ms < 1000) {
            return (Math.round(ms * 100) / 100) + ' ms';
        }
        const s = ms / 1000;
        return (Math.round(s * 100) / 100) + ' s';
    }

    static ns2ms(nanoseconds) {
        return Math.round((nanoseconds / 1000000));
    }

    static getHealthText(checks) {
        if (checks.length < 1) {
            return null;
        }

        if (checks.map(c => c.health === 'HEALTHY').indexOf(false) === -1) {
            return 'HEALTHY';
        }

        const lastCheckUnhealthy = checks.map(c => c.health === 'HEALTHY').pop() === false;

        if (lastCheckUnhealthy) {
            return 'UNHEALTHY';
        } else {
            return 'DEGRADED';
        }
    }

    static getHealthyPercent(checks) {
        if (checks.length < 1) {
            return 0;
        }
        const percent = (checks.map(c => c.health).filter(h => h === 'HEALTHY').length / checks.length) * 100;

        return Math.round(percent * 100) / 100;
    }

    static getUnhealthyPercent(checks) {
        if (checks.length < 1) {
            return 0;
        }
        return (checks.map(c => c.health).filter(h => h === 'UNHEALTHY').length / checks.length) * 100;
    }

    static getConnectionText(check) {
        switch (check.connection_status) {
            case 1:
                return 'Connected';

            case 2:
                return 'Host unreachable';

            case 3:
                return 'Connection timed out';

            default:
                return 'Failed to connect';
        }
    }

    static getAverageResponseTime(checks) {
        return checks.map(c => c.duration).reduce((a, b) => a + b, 0) / checks.length;
    }
}
