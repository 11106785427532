import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import ResponseTimeLineChart from "./ResponseTimeLineChart";
import { Analyst } from "../services/Analyst";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { loadChecks } from '../store/actions/actions'
import { isChecksFetching, isChecksLoaded } from '../store/query/query'

class CheckDefinitionListBlock extends React.PureComponent {
    interval = null;
    constructor(props) {
        super(props)
        this.loadIfNeedBe = this.loadIfNeedBe.bind(this)
    }

    componentDidMount () {
        this.loadIfNeedBe()
        if (this.props.autorefresh === true) {
            this.setupRefresh()
        }
    }
    componentDidUpdate (prevProps, prevState, snapshot) {
        if (this.props.autorefresh === false) {
            this.teardownRefresh()
        }

        if (this.props.autorefresh === true && this.interval === null) {
            this.setupRefresh()
        }
    }
    componentWillUnmount () {
        this.teardownRefresh()
    }
    setupRefresh() {
        this.interval = setInterval(this.loadIfNeedBe, this.props.definition.interval.count * 1000)
    }
    teardownRefresh() {
        if (this.interval === null) {
            return;
        }
        clearInterval(this.interval)
        this.interval = null
    }

    loadIfNeedBe() {
        if (this.props.checksFetching) {
            return
        }

        if (!this.props.checksLoaded) {
            return this.load()
        }

        if (this.props.checks.length === 0) {
            return
        }

        const lastCheckStartedAt = new Date(this.props.checks.sort((a, b) => new Date(b.started_at).getTime() - new Date(a.started_at).getTime())[0].started_at)
        const secondsSinceLastCheck = (Date.now() - lastCheckStartedAt.getTime()) * 0.001
        const secondsBetweenChecks = this.props.definition.interval.count;
        const averageCheckDuration = 1; // @todo Don't hardcode this

        if (secondsSinceLastCheck > (secondsBetweenChecks + averageCheckDuration)) {
            return this.load()
        }
    }

    load() {
        this.props.loadChecks(
          this.props.authToken,
          this.props.definition.id,
          new Date(Date.now() - (12 * 60 * 60 * 1000)), // 12 hours ago from now
          new Date(), // now
          10 // limit to 25 latest checks
        )
    }

    static nanosecondsToHuman(nanoseconds) {
        const ms = nanoseconds / 1000000;
        if (ms < 1000) {
            return (Math.round(ms * 100) / 100) + 'ms';
        }
        const s = ms / 1000;
        return (Math.round(s * 100) / 100) + 's';
    }

    render() {
        let classes = ''
            +' relative'
            +' py-8'
            +' px-4'
            +' rounded'
            +' text-grey-dark'
            +' shadow'
        ;

        if (this.props.isActive) {
            classes += ' bg-grey-lightest';
        }

        let healthText = this.getHealthText();

        let type = this.props.definition.type;

        let target = this.props.definition.uri ? this.props.definition.uri : this.props.definition.host;
        if (target.substr(0, 7) === 'http://') {
            target = target.substr(7);
        }
        if (target.substr(0, 8) === 'https://') {
            if (type === 'HTTP') {
                type = 'HTTPS';
            }
            target = target.substr(8);
        }
        if (target.substr(0, 4) === 'www.') {
            target = target.substr(4);
        }

        let bits = target.split(/\/(.*)/);
        target = bits[0];
        let targetSuffix;
        if (bits.length > 1) {
            targetSuffix = '/'+bits[1];
        }

        if (type === 'TCP') {
            targetSuffix = ':' + this.props.definition.port;
        }

        const checks = this.props.checks.sort((a, b) => new Date(b.started_at).getTime() - new Date(a.started_at).getTime()).slice(0, 10)

        return (
            <Link to={"/workspace/"+this.props.definition.workspace_id+"/detail/"+this.props.definition.id} className="text-white no-underline">
                <div className={classes}>
                    <p className="text-xs m-0 p-0 -mt-3">
                        {type}
                        {healthText}
                    </p>
                    <p className="m-0 p-0 pt-3 text-grey-darker font-bold overflow-hidden truncate text-sm z-20 relative">
                        <span>
                            {target}
                        </span>
                        <span className="font-normal text-grey-dark">
                            {targetSuffix}
                        </span>
                    </p>

                    <div className="absolute bottom-0 w-full -mx-4 -mt-6 z-10">
                        <div className="">
                            <ResponseTimeLineChart checks={checks} minimal={true} height={25} />
                        </div>
                    </div>
                </div>
            </Link>
        );
    }

    getHealthText() {

        if (this.props.checks.length < 1 || this.props.checksFetching) {
            return (
                <span className="float-right text-xs">
                    <FontAwesomeIcon icon="circle-notch" spin={true}/>
                </span>
            );
        }

        const health = Analyst.getHealthText(this.props.checks);

        switch (health) {
            case 'HEALTHY':
                const avgResponseTime = Analyst.nanosecondsToHuman(this.props.checks.map(c => c.duration).reduce((p, v) => v + p, 0) / this.props.checks.length);
                return (
                    <span className="float-right text-teal-dark text-xs">
                        {avgResponseTime}
                    </span>
                );

            case 'UNHEALTHY':
                return (
                    <span className="float-right text-pink-dark text-xs">
                        <FontAwesomeIcon icon="exclamation-triangle"/>
                    </span>
                );

            case 'DEGRADED':
                return (
                    <span className="float-right text-orange text-xs">
                        <FontAwesomeIcon icon="exclamation-triangle"/>
                    </span>
                );

            case null:
                return (
                    <span className="float-right text-xs">
                        <FontAwesomeIcon icon="circle-notch" spin={true}/>
                    </span>
                );

            default:
                throw new Error("Unexpected health " + health);
        }
    }
}

const mapStateToPropsFactory = (initialState, ownProps) => {
    return function (state) {
        return {
            authToken: state.authToken,
            checks: state.checks[ownProps.definition.id] ? state.checks[ownProps.definition.id].list : [],
            checksLoaded: isChecksLoaded(state, ownProps.definition.id),
            checksFetching: isChecksFetching(state, ownProps.definition.id)
        }
    }
}

const mapActionsToProps = (dispatch)  => {
    return bindActionCreators({ loadChecks }, dispatch);
}

export default connect(mapStateToPropsFactory, mapActionsToProps)(CheckDefinitionListBlock);
