import React, { Component } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class LoadingScreen extends Component {
    render() {
        return (
            <div className="bg-grey-lighter min-h-screen p-2 text-black text-xl font-light text-center">
                <p className="mt-8 pt-8">
                    <FontAwesomeIcon icon="circle-notch" spin={true}/>
                </p>
            </div>
        );
    }
}

export default LoadingScreen;
