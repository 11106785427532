import React, { Component } from "react";
import { Redirect } from 'react-router-dom'
import config from "../services/Config";
import Header from "../components/Header";
import { PrimaryButton } from '../components/Buttons'
import {TextInput} from "../components/FormInput";
import Footer from '../components/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const initialState = {
    type: 'HTTP',
    host: '',
    port: '',
    interval: 60,
    mode: 'form',
    http_url: '',
    definition_id: null
};

class CheckDefinitionCreate extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleHttpUrlChange = this.handleHttpUrlChange.bind(this);
        this.reset = this.reset.bind(this);
    }

    reset() {
        this.setState(initialState);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleHttpUrlChange(event) {
        let url = event.target.value;
        let len = url.length;

        if (len > 7) {
            len = 7;
        }
        if (len > 0 && url.substr(0, len) !== ('http://'+url).substr(0, len) && url.substr(0, len) !== ('https:/'+url).substr(0, len)) {
            url = 'http://' + url;
        }

        const parser = document.createElement('a');

        parser.href = url;

        let port = parser.port;
        if (!port) {
            port = parser.protocol === 'https:' ? 443 : 80;
        }

        this.setState({
            http_url: url,
            host: parser.hostname,
            port: port
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        var c = this;

        let body = 'type='+this.state.type
            +'&healthy_threshold=1'
            +'&unhealthy_threshold=1'
            +'&host='+this.state.host
            +'&port='+this.state.port
            +'&interval='+this.state.interval
            +'&workspace_id='+this.props.match.params.workspace;

        if (this.state.type === 'HTTP') {
            body += '&uri='+ encodeURI(this.state.http_url)
        }

        fetch(config.API_V1_BASE_URI + '/api/v1/check-definition', {
            method: 'POST',
            headers: {
                'X-Auth': this.props.auth.getToken(),
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
            },
            body: body
        }).then(function (response) {
            if (response.status === 200) {
                response.json().then(json => {
                    c.setState({
                        mode: 'success',
                        definition_id: json.data.check.id
                    });
                });
                return;
            }

            if (response.status === 401) {
                c.props.auth.logout();
                return;
            }

            response.json().then(json => {
                alert(json.data.errors[0].message);
            });
        });
    }

    render() {
        if (this.state.mode === 'success') {
            return (
              <Redirect to={"/workspace/"+this.props.match.params.workspace+"/detail/"+this.state.definition_id} />
            );
        }

        let definitionTypeSpecificFields;

        if (this.state.type === 'HTTP') {
            definitionTypeSpecificFields = (
                <div className="md:flex">

                    <div className="md:w-3/5 mb-4">
                        <label className="block text-grey-darker text-sm font-bold mb-2 text-left">
                            URL
                        </label>
                        <TextInput
                            name="http_url"
                            required={true}
                            value={this.state.http_url}
                            onChange={this.handleHttpUrlChange}
                            placeholder="http://example.com/" />
                    </div>

                    <div className="md:w-1/10">
                        <p className="px-2 py-4 md:pt-8 md:mt-2">
                            every
                        </p>
                    </div>

                    <div className="md:w-1/5">
                        <div className="md:pt-6">
                            <TextInput
                              name="interval"
                              type="number"
                              value={this.state.interval}
                              onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className="md:w-1/10">
                        <p className="px-2 py-4 md:pt-8 md:mt-2 ">
                            seconds
                        </p>
                    </div>

                    <input name="host" type="hidden" value={this.state.host} readOnly={true} className="bg-grey-light appearance-none rounded py-4 px-4 text-grey-darker"/>
                    <input name="port" type="hidden" value={this.state.port} readOnly={true} className="bg-grey-light appearance-none rounded py-4 px-4 text-grey-darker"/>

                </div>
            );
        } else {
            definitionTypeSpecificFields = (
                <div className="md:flex text-left">
                    <div className="md:w-2/5 mb-4">
                        <label className="block text-grey-darker text-sm font-bold mb-2">
                            Hostname or IP
                        </label>
                        <TextInput name="host" value={this.state.host} onChange={this.handleChange} placeholder="example.com" />
                    </div>

                    <div className="md:w-1/5 mb-4">
                        <label className="md:pl-2 block text-grey-darker text-sm font-bold mb-2">
                            Port
                        </label>
                        <div className="md:pl-2">
                            <TextInput name="port" type="number" value={this.state.port} onChange={this.handleChange} placeholder="80" />
                        </div>
                    </div>

                    <div className="md:w-1/10">
                        <p className="px-2 py-4 md:pt-8 md:mt-2 text-center">
                            every
                        </p>
                    </div>

                    <div className="md:w-1/5">
                        <div className="md:pt-6">
                            <TextInput
                                name="interval"
                                type="number"
                                value={this.state.interval}
                                onChange={this.handleChange} />
                        </div>
                    </div>

                    <div className="md:w-1/10">
                        <p className="px-2 py-4 md:pt-8 md:mt-2 text-center">
                            seconds
                        </p>
                    </div>
                </div>
            );
        }

        const activeTypeClasses = 'block w-full rounded bg-white border border-pink shadow-md font-medium text-grey-darkest rounded p-4 outline-none mb-4';
        const inactiveTypeClasses = 'block w-full rounded bg-grey-lighter border border-grey-lighter font-medium text-grey-dark p-4 outline-none mb-4';

        const activeSymbol = <span className="ml-2 bg-pink-lighter text-pink-light p-1 px-2 text-xs rounded-full float-right -mb-1"><FontAwesomeIcon icon="check"/></span>;
        const inactiveSymbol = <span className="ml-2 bg-grey-light text-grey-light p-1 px-2 text-xs rounded-full float-right -mb-1"><FontAwesomeIcon icon="check"/></span>;

        return (
            <div className="h-screen">

                <Header/>

                <div className="container max-w-lg mx-auto my-8 px-4">

                    <h1 className="text-3xl font-light text-center py-8">Create a new check</h1>

                    <p className="text-grey py-8 text-center">
                        What do you want to monitor?
                    </p>

                    <div>

                        <form onSubmit={this.handleSubmit} id="check-definition-create-form" className="text-center">

                            <div className="my-4 pb-8 mb-8 border-b max-w-md mx-auto">

                                <div className="flex">
                                    <div className="w-1/2">
                                        <div className="pr-4">
                                            <button type="button" className={this.state.type === 'HTTP' ? activeTypeClasses : inactiveTypeClasses} onClick={() => this.setState({type: 'HTTP'})}>
                                                HTTP
                                                {this.state.type === 'HTTP' ? activeSymbol : inactiveSymbol}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="w-1/2">
                                        <div className="pl-4">
                                            <button type="button" className={this.state.type === 'TCP' ? activeTypeClasses : inactiveTypeClasses} onClick={() => this.setState({type: 'TCP'})}>
                                                TCP
                                                {this.state.type === 'TCP' ? activeSymbol : inactiveSymbol}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {definitionTypeSpecificFields}

                            <div className="mt-8 text-right">
                                <PrimaryButton className="block w-full md:inline md:w-auto">Create</PrimaryButton>
                            </div>
                        </form>

                    </div>
                </div>

                <div><br/><br/><br/><br/></div>

                <Footer/>
            </div>
        );
    }
}

export default CheckDefinitionCreate;
