import React, { Component } from 'react'
import config from '../services/Config'
import { SecondaryButton } from './Buttons'
import { getWorkspace } from '../store/query/query'
import { TextInput } from './FormInput'
import { connect } from 'react-redux'

class InviteLink extends Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false,
      invite_code: null,
      loading_invite_code: true
    };
  }

  componentDidMount () {
    this.loadWorkspaceInviteCode();
  }

  loadWorkspaceInviteCode () {
    fetch(config.API_V1_BASE_URI + '/api/v1/workspace/' + this.props.workspaceId + '/invite-code', {
      headers: {
        'X-Auth': this.props.auth.getToken()
      }
    }).then((r) => r.json())
      .then((json) => {
        this.setState({invite_code: json.data.code})
      })
  }
  render () {
    if (!this.state.open) {
      return (
        <div className="mt-8">
          <SecondaryButton
            onClick={() => this.setState({open:true})}
            disabled={!this.state.invite_code}
          >
            Add a new team member
          </SecondaryButton>
        </div>
      )
    }

    const ws = getWorkspace(this.props.workspaces, this.props.workspaceId);

    return (
      <div className="mt-8">
        <label className="block text-grey-darker text-sm font-bold mb-4">
          Share the below invite link with team members to join this workspace
        </label>
        <TextInput readonly={true}
                   value={`https://app.whoops.io/join/${this.state.invite_code}?ws=${encodeURI(ws ? ws.name : '...')}`}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    workspaces: state.workspaces
  }
}

export default connect(mapStateToProps)(InviteLink);
