import React, { Component } from "react";
import StripeCheckout from 'react-stripe-checkout';
import Header from "../components/Header";
import config from "../services/Config";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { SecondaryButton } from '../components/Buttons'
import Footer from '../components/Footer'
import { loadUser } from '../store/actions/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

class Upgrade extends Component {

    constructor(props) {
        super(props);

        this.state = {
            plan: 'PRO',
            loading: false,
            successMessage: null,
            workspace: null
        };
        this.changeToFree = this.changeToFree.bind(this);
        this.changeToPro = this.changeToPro.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
        this.handleChange = this.handleChange.bind(this);

        fetch(config.API_V1_BASE_URI + '/api/v1/workspace/' + this.props.match.params.workspace + '/subscription', {
            headers: {
                'X-Auth': this.props.auth.getToken()
            }
        }).then((r) => r.json())
            .then((json) => {
                this.setState({plan: json.data.subscription.plan});
            });

        fetch(config.API_V1_BASE_URI + '/api/v1/workspace/' + this.props.match.params.workspace, {
            headers: {
                'X-Auth': this.props.auth.getToken()
            }
        }).then((r) => r.json())
            .then((json) => {
                this.setState({workspace: json.data.workspace});
            });
    }

    componentDidMount () {
        this.props.loadUser(this.props.authToken);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    onOpen() {
        //
    }

    onClose() {
        //
    }

    changeToPro(token) {
        this.setState({loading: true});

        let body = new URLSearchParams();

        body.append('plan', 'PRO');
        body.append('stripe_email', token.email);
        body.append('stripe_token', token.id);

        fetch(config.API_V1_BASE_URI + '/api/v1/workspace/' + this.props.match.params.workspace + '/subscription', {
            method: 'POST',
            headers: {
                'X-Auth': this.props.auth.getToken(),
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
            },
            body: body
        }).then((r) => r.json())
            .then((json) => {
                this.setState({loading: false, successMessage: "You've successfully upgraded to "+json.data.subscription.plan});
            });
    }

    changeToFree() {
        this.setState({loading: true});

        let body = new URLSearchParams();
        body.append('plan', 'FREE');
        body.append('stripe_email', this.props.user.email);

        fetch(config.API_V1_BASE_URI + '/api/v1/workspace/' + this.props.match.params.workspace + '/subscription', {
            method: 'POST',
            headers: {
                'X-Auth': this.props.auth.getToken(),
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
            },
            body: body
        }).then((r) => r.json())
            .then((json) => {
                this.setState({loading: false, successMessage: "You've successfully upgraded to "+json.data.subscription.plan});
            });
    }

    render() {

        if (!this.props.user || !this.state.workspace || this.state.loading) {
            return (
                <div className="h-screen">
                    <Header/>

                    <div className="container mx-auto pt-6 max-w-sm text-center">
                        <h2 className="text-black text-2xl font-light p-4 m-8">Processing...</h2>
                        <p>
                            <span className="mx-2"><FontAwesomeIcon icon="circle-notch" spin={true} /></span>
                            Hold on, this may take a few seconds
                        </p>
                    </div>
                </div>
            );
        }

        if (this.state.successMessage) {
            return (
                <div className="h-screen">
                    <Header/>

                    <div className="container mx-auto pt-6 max-w-sm text-center">

                        <h2 className="text-black text-2xl font-light p-4 m-8">{this.state.successMessage}</h2>

                        <div className="mt-8">
                            <NavLink className="bg-blue hover:bg-blue-light text-white font-bold py-2 px-4 border-b-4 border-blue-dark hover:border-blue rounded no-underline" to={"/workspace/"+this.props.match.params.workspace+"/dashboard"}>
                                Continue to my dashboard
                            </NavLink>
                        </div>
                    </div>
                </div>
            );
        }

        let upgradeProButton = <StripeCheckout
            token={this.changeToPro}
            opened={this.onOpen}
            closed={this.onClose}
            name="Upgrade to Pro"
            description="$5 per month"
            stripeKey={config.STRIPE_PUBLIC_KEY}
            email={this.props.user.email}
            label={this.state.plan === 'PRO' ? "Update credit/debit card details" : "Upgrade to Pro"}
        />;

        let currentSubscriptionText = '';

        switch (this.state.plan) {
            case 'FREE':
                currentSubscriptionText = (
                    <p className="text-grey-dark py-8 text-center">
                        You're currently on the <strong>Free Plan</strong>
                    </p>
                );
                break;

            case 'PRO':
                currentSubscriptionText = (
                    <div>
                      <p className="text-grey-dark py-8 text-center">
                        You're currently on the <strong>Pro Plan</strong>
                      </p>
                    </div>
                );
                break;
            default:
                break;
        }

        return (
            <div className="h-screen">
                <Header/>

                <div className="container mx-auto pt-6 text-center max-w-xl">

                    <h1 className="text-3xl font-light text-center py-8">{this.state.workspace.name}</h1>

                    {currentSubscriptionText}


                    <div className="lg:flex leading-loose">

                        <div className="lg:w-1/2">
                            <div className="bg-white p-4 m-8 shadow rounded">
                                <h3 className="font-light text-2xl p-4">Free Plan</h3>
                                <p className="text-grey-dark py-2 text-lg">
                                    Free Forever
                                </p>

                                <ul>
                                    <li>20 HTTP(S) or TCP checks</li>
                                    <li>60 second intervals</li>
                                    <li>2 users</li>
                                    <li>30 day retention</li>
                                </ul>

                                <div className="py-4">
                                    <SecondaryButton disabled={this.state.plan === 'FREE'} onClick={this.changeToFree}>Downgrade to Free Plan</SecondaryButton>
                                </div>
                            </div>
                        </div>

                        <div className="lg:w-1/2">
                            <div className="bg-white p-4 m-8 shadow rounded">
                                <h3 className="font-light text-2xl p-4">Pro Plan</h3>
                                <p className="text-grey-dark py-2 text-lg">
                                    $5 per month
                                </p>

                                <ul>
                                    <li>500 HTTP(S) or TCP checks</li>
                                    <li>10 second intervals</li>
                                    <li>Unlimited users</li>
                                    <li>12 month retention</li>
                                </ul>

                                <div className="py-4">
                                    {upgradeProButton}
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div><br/><br/><br/><br/></div>

                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        authToken: state.authToken
    }
}

const mapActionsToProps = (dispatch)  => {
    return bindActionCreators({ loadUser }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(Upgrade);
