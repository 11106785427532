import React, { Component } from "react";

class Footer extends Component {
    render() {
        return (
            <div className="mt-4">
              <div className="px-4 py-8 border-t">
                <ul className="text-sm p-0 m-0 text-right">
                  <li className="m-0 p-0">
                    <a href="https://whoops.io/support" className="text-grey no-underline p-4 hover:text-grey-dark">
                      Support
                    </a>
                  </li>
                </ul>
              </div>
            </div>
        );
    }
}

export default Footer;
