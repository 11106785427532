import React from "react";
import { Line } from 'react-chartjs-2'

class LineChart extends React.PureComponent {
    render () {
        return (
          <Line height={this.props.height} data={this.props.data} options={this.props.options} />
        );
    }
}

export default LineChart;
