import React, { Component } from "react";
import {NavLink, Redirect} from "react-router-dom";
import config from '../services/Config.js'
import {Logo} from "../components/Logo";

class Login extends Component {
    render() {
        if (this.props.auth.isLogged()) {
            return (
                <Redirect to={"/workspaces?forward"} />
            );
        }

        let location = window.location.protocol + '//' + window.location.host + '/';

        const loginUrlGithub = config.AUTH_V1_BASE_URI + '/auth/v1/login?provider=github&redirect=' + encodeURI(location);
        const loginUrlGoogle = config.AUTH_V1_BASE_URI + '/auth/v1/login?provider=google&redirect=' + encodeURI(location);

        return (
            <div className="container mx-auto pt-8 text-center max-w-sm leading-loose">

                <div className="m-8">
                    <Logo/>
                </div>

                <div className="shadow p-8 bg-grey-lightest rounded-lg">
                    <h2 className="text-xl font-light p-4 mb-8 text-grey-darkest">Welcome back</h2>

                    <div>
                        <a className="p-2 py-3 bg-grey-darkest hover:bg-black text-white rounded no-underline text-sm font-bold" href={loginUrlGithub}>
                            <img src="/img/GitHub-Mark-Light-32px.png" alt="GitHub" className="-mb-2 -mt-2 mr-4 inline" style={{height: "24px"}} />
                            Sign in with GitHub
                        </a>
                    </div>

                    <div className="mt-8">
                        <a href={loginUrlGoogle}>
                            <img src="/img/google/1x/btn_google_signin_dark_normal_web.png" alt="Sign in with Google" className="inline" />
                        </a>
                    </div>

                    <div className="pt-4">
                        <NavLink className="text-grey hover:text-grey-darker text-sm no-underline" to="/signup">
                            Don't have an account? Click here to sign up
                        </NavLink>
                    </div>
                </div>

            </div>

        );
    }
}

export default Login;
