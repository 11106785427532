// React
import React, { Component } from "react";
import { Switch, Router } from "react-router-dom";

// Components
import LoginRedirect from "./components/LoginRedirect"
import Auth from "./services/Auth";

// Routes
import Account from "./pages/Account";
import Home from "./pages/Home";
import CheckDefinitionCreate from "./pages/CheckDefinitionCreate";
import CheckDefinitionEdit from "./pages/CheckDefinitionEdit";
import Details from "./pages/Details";
import Dashboard from "./pages/Dashboard";
import Join from "./pages/Join";
import Login from "./pages/Login"
import Logout from "./pages/Logout"
import NotFound from "./pages/NotFound"
import Workspaces from "./pages/Workspaces";
import WorkspaceCreate from "./pages/WorkspaceCreate";
import Upgrade from "./pages/Upgrade";
import Settings from "./pages/Settings";
import Signup from "./pages/Signup";

// Extras
import "./loaders/FontAwesomeLoader";
import "./loaders/TailwindLoader";
import { bindActionCreators } from 'redux'
import { setAuthToken } from './store/actions/actions'
import { connect } from 'react-redux'
import WhoopsRoute from './components/WhoopsRoute'


class App extends Component {
    constructor(props) {
        super(props);

        this.loadAuthSession()

        let auth = new Auth(this);
        this.state = {
            auth: auth,
            user: null,
        };
    }

    componentDidMount () {
        // @todo Re-implement checking if user is actually logged in
    }

    loadAuthSession() {
        this._localStorage = window.localStorage;

        let params = new URLSearchParams(window.location.search);
        if (params.get('api_key')) {
            this._localStorage.setItem('auth_token', params.get('api_key'));

            const target = this._localStorage.getItem('loginRedirect');

            this._localStorage.removeItem('loginRedirect');
            if (target) {
                // @todo Should this be moved to App component state?
                window.location = target;
            } else {
                window.location = '/';
            }
        }

        this.props.setAuthToken(this._localStorage.getItem('auth_token'));
    }

    render() {
        if (!this.state.auth.isLogged()) {
            return (
                <Router history={this.props.browserHistory}>
                    <div>
                        <div className="content min-h-screen bg-grey-lighter">
                            <Switch>
                                <WhoopsRoute path="/login" component={Login} auth={this.state.auth}/>
                                <WhoopsRoute path="/signup" component={Signup} auth={this.state.auth}/>
                                <WhoopsRoute path="/" component={LoginRedirect} auth={this.state.auth}/>
                            </Switch>
                        </div>
                    </div>
                </Router>
            )
        }

        return (
            <Router history={this.props.browserHistory}>
                <Switch>
                    <WhoopsRoute exact path="/" component={Home} auth={this.state.auth}/>
                    <WhoopsRoute path="/login" component={Login} auth={this.state.auth}/>
                    <WhoopsRoute path="/signup" component={Signup} auth={this.state.auth}/>
                    <WhoopsRoute path="/logout" component={Logout} auth={this.state.auth}/>
                    <WhoopsRoute path="/join/:invite" component={Join} auth={this.state.auth} user={this.state.user}/>
                    <WhoopsRoute path="/workspaces" component={Workspaces} auth={this.state.auth}/>
                    <WhoopsRoute path="/account" component={Account}/>
                    <WhoopsRoute path="/workspace/create" component={WorkspaceCreate} auth={this.state.auth} />
                    <WhoopsRoute path="/workspace/:workspace/detail/:id" component={Details} auth={this.state.auth} user={this.state.user}/>
                    <WhoopsRoute path="/workspace/:workspace/dashboard" component={Dashboard} auth={this.state.auth} user={this.state.user}/>
                    <WhoopsRoute path="/workspace/:workspace/check-definition/create" component={CheckDefinitionCreate} auth={this.state.auth} user={this.state.user}/>
                    <WhoopsRoute path="/workspace/:workspace/check-definition/:id/edit" component={CheckDefinitionEdit} auth={this.state.auth} user={this.state.user}/>
                    <WhoopsRoute path="/workspace/:workspace/upgrade" component={Upgrade} auth={this.state.auth}/>
                    <WhoopsRoute path="/workspace/:workspace/settings" component={Settings} auth={this.state.auth}/>
                    <WhoopsRoute path="/" component={NotFound} auth={this.state.auth}/>
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        browserHistory: state.browserHistory
    }
}

const mapActionsToProps = (dispatch)  => {
    return bindActionCreators({ setAuthToken }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(App);
