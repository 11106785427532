import React from 'react';
import config from '../services/Config.js';
import CheckDefinitionDetailsPane from '../components/CheckDefinitionDetailsPane';
import CheckDefinitionListBlock from "../components/CheckDefinitionListBlock";
import {Scrollbars} from 'react-custom-scrollbars';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Header from "../components/Header";
import Footer from '../components/Footer'
import { Redirect } from 'react-router-dom'

class Details extends React.Component {
    constructor(props) {
        super(props);
        let c = this;
        c.state = {
            definitions: [],
            definition: null,
            checks: [],
            connectionStatuses: [],
            loadingChecks: true,
            loadingDefinitions: true,
            checksFrom: new Date(Date.now() - (60 * 60 * 24 * 1000)).toISOString(),
            dateRange: '24hours',
            disabledAgents: []
        };

        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.handleChecksFromChange = this.handleChecksFromChange.bind(this);
        this.selectDefinition = this.selectDefinition.bind(this);
        this.filterAgents = this.filterAgents.bind(this);
        this.toggleAgent = this.toggleAgent.bind(this);

        this.loadConnectionStatuses();
        this.loadDefinitions();
    }

    filterAgents(disabledAgents) {
        this.setState({disabledAgents: disabledAgents})
        this.handleRefresh();
    }

    toggleAgent(agent) {
        if (this.state.disabledAgents.includes(agent)) {
            this.setState({disabledAgents: this.state.disabledAgents.filter(a => a !== agent)});
        } else {
            this.setState({disabledAgents: [...this.state.disabledAgents, agent]})
        }
        this.loadChecks();
    }

    loadDefinitions() {
        let c = this;

        fetch(config.API_V1_BASE_URI + '/api/v1/check-definition?workspace_id='+this.props.match.params.workspace, {
            headers: {
                'X-Auth': c.props.auth.getToken()
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    return response;
                }

                if (response.status === 401) {
                    c.props.auth.logout();
                }

                alert('Sorry, something went wrong');
            })
            .then((r) => r.json())
            .then((json) => {
                if (json.status !== 'success') {
                    alert('Sorry, something went wrong');
                    return;
                }
                c.setState({definitions: json.data.checks, loadingDefinitions: false});
                c.selectDefinitionId(this.props.match.params.id);
            });
    }

    loadConnectionStatuses() {
        let c = this;

        fetch(config.API_V1_BASE_URI + '/api/v1/connection-status', {
            headers: {
                'X-Auth': c.props.auth.getToken()
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    return response;
                }

                if (response.status === 401) {
                    c.props.auth.logout();
                }

                alert('Sorry, something went wrong');
            })
            .then((r) => r.json())
            .then((json) => {
                if (json.status !== 'success') {
                    alert('Sorry, something went wrong');
                    return;
                }
                c.setState({connectionStatuses: json.data.statuses});
            });
    }

    selectDefinition(definition) {
        this.setState({
            loadingChecks: true,
            checks: [],
            definition: definition
        }, this.loadChecks);
    }

    selectDefinitionId(id) {
        for (let i in this.state.definitions) {
            const def = this.state.definitions[i];
            if (def.id === id) {
                this.selectDefinition(def);
                return;
            }
        }

        console.error('Problem selecting definition by ID');
    }

    handleDateRangeChange(e) {
        let newFrom;
        this.setState({
            dateRange: e.target.value
        });
        switch (e.target.value) {
            case '7days':
                newFrom = new Date(Date.now() - (7 * 24 * 60 * 60 * 1000));
                break;

            case '24hours':
                newFrom = new Date(Date.now() - (24 * 60 * 60 * 1000));
                break;

            case '1hour':
                newFrom = new Date(Date.now() - (60 * 60 * 1000));
                break;

            case '5mins':
                newFrom = new Date(Date.now() - (5 * 60 * 1000));
                break;

            case 'custom':
                return;

            default:
                console.error("Unexpected date range " + e.target.value);
                return;
        }

        this.setState({checksFrom: newFrom.toISOString()}, this.loadChecks);
    }

    handleRefresh () {
        this.loadChecks();
    }

    handleChecksFromChange(e) {
        this.setState({checksFrom: e.target.value}, this.loadChecks);
    }

    componentWillReceiveProps(newProps) {
        this.selectDefinitionId(newProps.match.params.id);
    }

    render() {
        let definitionList = this.renderDefinitionList();

        let pane = '';

        if (this.state.loadingChecks && this.state.checks.length === 0) {
            pane = (
                <div className="h-full align-middle text-lg pt-8 mt-8 text-center">
                    <FontAwesomeIcon icon="circle-notch" spin={true}/>
                </div>
            );
        }

        if (this.state.definition) {
            pane = <CheckDefinitionDetailsPane
                definition={this.state.definition}
                connectionStatuses={this.state.connectionStatuses}
                handleDateRangeChange={this.handleDateRangeChange}
                handleRefresh={this.handleRefresh}
                loadingChecks={this.state.loadingChecks}
                dateRange={this.state.dateRange}
                filterAgents={this.filterAgents}
                toggleAgent={this.toggleAgent}
                disabledAgents={this.state.disabledAgents}
                handleChecksFromChange={this.handleChecksFromChange} />;
        }

        return (
            <div className="">
                <Header/>

                <div className="lg:flex w-full">

                    <div className="lg:w-1/2 xl:w-1/5 hidden lg:block">
                        <Scrollbars style={{ height: '100%' }} className="border border-r border-t-0">
                            {definitionList}
                        </Scrollbars>
                    </div>

                    <div className="lg:w-1/2 xl:w-4/5 lg:min-h-screen">
                        {pane}
                    </div>
                </div>

                <Footer/>
            </div>
        );
    }

    renderDefinitionList() {

        if (this.state.loadingDefinitions) {
            return (
                <div className="text-center p-8">
                    <FontAwesomeIcon icon="circle-notch" spin={true}/>
                </div>
            )
        }

        let c = this;
        let definitionList = this.state.definitions.map(function (definition) {
            const isActive = c.state.definition && c.state.definition.id === definition.id;

            return <div key={definition.id} className="px-2 py-4 sm:px-4 lg:px-6">
                <CheckDefinitionListBlock
                    definition={definition}
                    isActive={isActive}
                    autorefresh={!isActive} />
            </div>
        });

        if (this.state.definitions.length < 1) {
            definitionList = (
              <Redirect to={"/workspace/" + c.props.match.params.workspace + "/dashboard"} />
            );
        }

        return definitionList;
    }
}

export default Details;
