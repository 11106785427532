import React, { Component } from "react";
import config from '../services/Config'
import { Redirect } from 'react-router-dom'
import Header from '../components/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Join extends Component {
    constructor (props) {
        super(props);
        this.state = {
            redirect: null,
            workspaceTargetName: "...",
            joined: false
        }
    }

    componentDidMount () {
        const c = this;

        const params = new URLSearchParams(this.props.location.search);
        const ws = params.get('ws')
        if (ws) {
            this.setState({
                workspaceTargetName: ws
            });
        }

        fetch(config.API_V1_BASE_URI + '/api/v1/workspace-assignment', {
            method: 'POST',
            headers: {
                'X-Auth': this.props.auth.getToken(),
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
            },
            body: "invite_code=" + encodeURI(this.props.match.params.invite)
        }).then((r) => {
            if (r.status === 200) {
                r.json().then(json => {
                    c.setState({
                        joined: true
                    });
                    setTimeout(() => {
                        c.setState({
                            redirect: `/workspace/${json.data.assignment.workspace_id}/dashboard`
                        });
                    }, 2000);
                });
                return;
            }

            if (r.status === 401) {
                this.props.auth.logout();
                return;
            }

            r.json().then(json => {
                alert(json.data.errors[0].message);
            });
        });
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        let body = (
          <p className="text-grey-darkest mb-4">
              Joining... <span className="ml-2"><FontAwesomeIcon icon="circle-notch" spin={true}/></span>
          </p>
        );
        if (this.state.joined === true) {
            body = (
              <div>
                  <p className="text-grey-darkest mb-4">
                      Joined <span className="ml-2 bg-green-lighter text-green-light p-2 rounded-full"><FontAwesomeIcon icon="check"/></span>
                  </p>
                  <p className="text-grey-dark mt-8">
                      Redirecting you to your new workspace...
                  </p>
              </div>
            );
        }

        return (
          <div>
              <Header/>
              <div className="p-4 text-center leading-loose mt-8">
                  <p className="mb-8">
                      <span className="p-2 px-4 text-white font-bold bg-grey rounded-full">{this.state.workspaceTargetName}</span>
                  </p>
                  {body}
              </div>
          </div>
        );
    }
}

export default Join;
