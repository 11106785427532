import React, { Component } from 'react'
import config from '../services/Config'
import { TextInput } from './FormInput'
import { PrimaryButton, SecondaryButton } from './Buttons'

class NotificationRuleCreator extends Component {
  constructor (props) {
    super(props);
    this.state = {
      open: false,
      email_address: '',
    }
    this.handleChange = this.handleChange.bind(this);
    this.create = this.create.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }
  create(event) {
    event.preventDefault();

    fetch(config.API_V1_BASE_URI + '/api/v1/workspace/' + this.props.workspace + '/notification-rule', {
      method: 'POST',
      headers: {
        'X-Auth': this.props.auth.getToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        type: 'EMAIL',
        details: {
          email_address: this.state.email_address
        }
      })
    }).then((r) => {
      if (r.status === 200) {
        this.setState({open: false, email_address: ''});
        this.props.onCreated();
        return;
      }

      if (r.status === 401) {
        this.props.auth.logout();
        return;
      }

      r.json().then(json => {
        alert(json.data.errors[0].message);
      });
    });
  }
  render () {
    if (this.state.open) {
      return <div className="text-center max-w-md mx-auto mt-8">
        <form onSubmit={this.create}>
          <div>
            <TextInput name="email_address" type="email" placeholder="Email address" onChange={this.handleChange} value={this.state.email_address} />
          </div>
          <div className="flex mt-4">
            <div className="w-1/2 text-right ">
              <SecondaryButton type="button" onClick={() => this.setState({open: !this.state.open})}>Cancel</SecondaryButton>
            </div>
            <div className="w-1/2 text-left">
              <PrimaryButton className="ml-2">Save</PrimaryButton>
            </div>
          </div>
        </form>
      </div>
    }

    return <div className="text-center max-w-md mx-auto pt-8">
      <SecondaryButton onClick={() => this.setState({open: !this.state.open})}>Add a new notification rule</SecondaryButton>
    </div>
  }
}

export default NotificationRuleCreator;
