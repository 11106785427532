import React, { Component } from "react";

export default class RevealOnClickText extends Component {
    constructor(props) {
        super(props);
        this.state = {clicked: false}
    }
    render() {

        const text = this.state.clicked ? this.props.text : "".padStart(this.props.text.length, '*');

        return (
            <span>
                <span>
                    {text}
                </span>
                <span onClick={() => {this.setState({clicked: !this.state.clicked})}} className="cursor-pointer ml-2 text-blue font-sans">
                    {this.state.clicked ? "Hide" : "Show"}
                </span>
            </span>
        );
    }
}