import React from 'react'
import AverageResponseTimeColumnChart from './AverageResponseTimeColumnChart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Analyst } from '../services/Analyst'
import UptimeDoughnutChart from './UptimeDoughnutChart'
import { NavLink } from 'react-router-dom'
import { Timeline } from './Timeline'
import { getChecks, isChecksFetching, isChecksLoaded } from '../store/query/query'
import { loadChecks, disableAgent, enableAgent } from '../store/actions/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { DateTimeInput } from './DateTimeInput'
import ResponseTimeLineChart from './ResponseTimeLineChart'

class CheckDefinitionDetailsPane extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            from: () => new Date(Date.now() - (60 * 60 * 1000)), // 1 hour ago. Make sure this matches <DateTimeInput>'s initial value
            to: () => Date.now(), // now. Make sure this matches <DateTimeInput>'s initial value
        }
        this.onFromDatetimeChange = this.onFromDatetimeChange.bind(this)
        this.onToDatetimeChange = this.onToDatetimeChange.bind(this)
        this.load = this.load.bind(this)
        this.loadIfNeedBe = this.loadIfNeedBe.bind(this)
    }
    componentDidMount () {
        this.load()
        this.setupRefresh()
    }
    componentWillUnmount () {
        this.teardownRefresh()
    }
    componentDidUpdate(prevProps) {
        if (prevProps.definition.id !== this.props.definition.id) {
            this.teardownRefresh()
            this.load()
            this.setupRefresh()
        }
        // @todo un-load previously loaded data to save on memory
    }
    setupRefresh() {
        this.setState({
            interval: setInterval(this.loadIfNeedBe, this.props.definition.interval.count * 1000)
        })
    }
    teardownRefresh() {
        if (this.state.interval === null) {
            return;
        }
        clearInterval(this.state.interval)
        this.setState({
            interval: null
        })
    }
    loadIfNeedBe() {
        if (this.props.checksFetching) {
            return
        }

        if (!this.props.checksLoaded) {
            return this.load()
        }

        if (this.props.checks.length === 0) {
            return
        }

        const lastCheckStartedAt = new Date(this.props.checks[0].started_at)
        const secondsSinceLastCheck = (Date.now() - lastCheckStartedAt.getTime()) * 0.001
        const secondsBetweenChecks = this.props.definition.interval.count;
        const averageCheckDuration = 1; // @todo Don't hardcode this

        if (secondsSinceLastCheck > (secondsBetweenChecks + averageCheckDuration)) {
            return this.load()
        }
    }

    onFromDatetimeChange(date) {
        this.setState({from: date}, this.load)
    }

    onToDatetimeChange(date) {
        this.setState({to: date}, this.load)
    }

    toggleAgent(agent) {
        if (this.props.disabledAgents.has(agent)) {
            this.props.enableAgent(agent)
        } else {
            this.props.disableAgent(agent)
        }

    }

    load() {
        this.props.loadChecks(
          this.props.authToken,
          this.props.definition.id,
          this.state.from(),
          this.state.to(),
          null // no limit
        )
    }

    render() {
        const target = this.props.definition.uri
            ? this.props.definition.uri
            : (this.props.definition.host + ':' + this.props.definition.port);

        const avgResponseTime = this.props.checks.length > 0
            ? <span>{Analyst.ns2ms(Analyst.getAverageResponseTime(this.props.checks)).toLocaleString()}ms</span>
            : '0';

        return (
            <div className={(this.props.checksFetching ? 'opacity-50' : '')}>

                <div className="xl:flex w-full">
                    <div className="xl:w-1/2">

                        <div className="p-8 text-lg text-left overflow-hidden" style={{textOverflow: "ellipsis"}}>
                            <span className="pr-4 text-grey-dark">{this.props.definition.type}</span>

                            <span className="pr-4 font-medium">{target}</span>

                            <p className="text-grey-dark text-sm mt-4">
                                Checked
                                every <strong>{this.props.definition.interval.count / this.props.definition.interval.increment} seconds</strong>

                                <NavLink to={`/workspace/${this.props.definition.workspace_id}/check-definition/${this.props.definition.id}/edit`}
                                         className="text-grey ml-2 hover:text-grey-light text-xs p-2 rounded-full">
                                    <FontAwesomeIcon icon="cog" />
                                </NavLink>
                            </p>
                        </div>

                    </div>
                    <div className="xl:w-1/2">

                        <div className="m-4 relative flex justify-end">
                            <div className="bg-white rounded-l-lg ">
                                <button className="rounded hover:bg-grey-lightest py-2 px-3 border-r border-grey-lighter text-xs block" onClick={this.load} disabled={this.props.checksFetching}>
                                    <FontAwesomeIcon icon="sync" spin={this.props.checksFetching} />
                                </button>
                            </div>
                            <div className="flex bg-white rounded-r-lg flex-1 xl:flex-initial">
                                <div className="w-1/2 text-left flex">
                                    <div className="py-2 text-grey-dark pl-4 pr-1">
                                        From:
                                    </div>
                                    <DateTimeInput onDatetimeChange={this.onFromDatetimeChange} initialValue="1h" />
                                </div>

                                <div className="w-1/2 text-right flex justify-end">
                                    <div className="py-2 text-grey-dark pl-4 pr-1">
                                        To:
                                    </div>
                                    <div className="pr-4">
                                        <DateTimeInput onDatetimeChange={this.onToDatetimeChange} initialValue="now" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="text-right mx-4">
                            {this.props.agents.map(a => {
                                const isActive = !this.props.disabledAgents.has(a);
                                if (isActive) {
                                    return (
                                        <span className="text-sm text-grey-darker py-2 px-3 rounded-full ml-2 cursor-pointer" onClick={() => this.toggleAgent(a)} key={a}>
                                            {a}
                                            <span className="text-grey-darker text-xs p-1 ml-1">
                                            <FontAwesomeIcon icon="check" />
                                            </span>
                                        </span>
                                    )
                                } else {
                                    return (
                                        <span className="text-sm bg-grey-lighter text-grey py-2 px-3 rounded-lg ml-2 cursor-pointer" onClick={() => this.toggleAgent(a)} key={a}>
                                            {a}
                                            <span className="bg-grey-light text-grey-light text-xs p-1 ml-1 rounded-full">
                                                <FontAwesomeIcon icon="check" />
                                            </span>
                                        </span>
                                    )
                                }
                            })}
                        </div>

                    </div>
                </div>

                <div className="xl:flex w-full">
                    <div className="xl:w-2/3">
                        <div className="lg:flex leading-loose">
                            <div className="lg:w-1/2">
                                <div className="text-center relative py-4">
                                    <p className="text-grey my-0">
                                        <FontAwesomeIcon icon="heartbeat"/>
                                    </p>
                                    <h3 className="font-medium text-black absolute w-full my-6 text-4xl">
                                        {Analyst.getHealthyPercent(this.props.checks)}% Uptime
                                    </h3>
                                    <UptimeDoughnutChart checks={this.props.checks} height={100}/>
                                </div>
                            </div>
                            <div className="lg:w-1/4">
                                <div className="text-center mt-4 mx-auto">
                                    <p className="text-grey my-0">
                                        <FontAwesomeIcon icon="stopwatch"/>
                                    </p>
                                    <span className="font-medium text-4xl">{avgResponseTime}</span>
                                    <p className="text-sm ">Avg. response time</p>
                                </div>
                            </div>
                            <div className="lg:w-1/4">
                                <div className="text-center mt-4 mx-auto">
                                    <p className="text-grey my-0">
                                        <FontAwesomeIcon icon="calendar-check"/>
                                    </p>
                                    <span className="font-medium text-4xl">{this.props.checks.length.toLocaleString()}</span>
                                    <p className="text-sm ">Checks since {this.state.from().toLocaleString()}</p>
                                </div>
                            </div>
                        </div>
                        <div className="p-8">
                            <AverageResponseTimeColumnChart checks={this.props.checks} height={window.innerWidth < 800 ? 250 : 100}/>
                        </div>
                        <div className="p-8">
                            <ResponseTimeLineChart checks={this.props.checks} height={window.innerWidth < 800 ? 250 : 100}/>
                        </div>
                    </div>
                    <div className="xl:w-1/3">
                        <h3 className="font-medium mb-4 text-center text-grey-dark">Recent activity</h3>
                        <div className="px-8">
                            <Timeline checks={this.props.checks} connectionStatuses={this.props.connectionStatuses} fetching={this.props.checksFetching} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        authToken: state.authToken,
        checks: getChecks(state, ownProps.definition.id, null, state.agentsDisabled),
        checksLoaded: isChecksLoaded(state, ownProps.definition.id),
        checksFetching: isChecksFetching(state, ownProps.definition.id),
        agents: [...new Set(getChecks(state, ownProps.definition.id).map(c => c.agent))],
        disabledAgents: state.agentsDisabled,
    }
}

const mapActionsToProps = (dispatch)  => {
    return bindActionCreators({ loadChecks, disableAgent, enableAgent }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(CheckDefinitionDetailsPane);
