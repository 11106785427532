import React from 'react'
import { setRoute, setActiveWorkspace } from '../store/actions/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import config from '../services/Config.js'
import * as ReactGA from "react-ga";

class PageviewTracker extends React.Component {
  componentDidMount () {
    ReactGA.initialize(config.GOOGLE_ANALYTICS_TRACKING_CODE);
    this.trackPageview()
  }
  componentDidUpdate (prevProps, prevState, snapshot) {
    this.trackPageview()
  }
  trackPageview() {
    if (this.isReduxOutOfDate()) {
      this.props.setRoute(this.props.match, this.props.location);
      if (this.props.match.params.workspace) {
        this.props.setActiveWorkspace(this.props.match.params.workspace);
      }
      if (config.GOOGLE_ANALYTICS_ENABLED === true) {
        ReactGA.pageview(this.props.location.pathname)
      } else {
        console.log('Pretending to track pageview: ' + this.props.location.pathname);
      }
    }
  }
  isReduxOutOfDate() {
    if (this.props.reduxLocation === null) {
      return true
    }

    return !(this.props.location.pathname === this.props.reduxLocation.pathname
      && this.props.location.search === this.props.reduxLocation.search
      && this.props.location.hash === this.props.reduxLocation.hash);


  }
  render() {
    return this.props.component
  }
}

const mapStateToProps = (state) => {
  return {
    browserHistory: state.browserHistory,
    reduxLocation: state.routeLocation
  }
}

const mapActionsToProps = (dispatch)  => {
  return bindActionCreators({ setRoute, setActiveWorkspace }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(PageviewTracker);
