import React from "react";
import LineChart from "./LineChart";

class ResponseTimeLineChart extends React.PureComponent {
    render() {
        const colorSets = [
            {
                healthy: {
                    border: 'rgb(146,245,157)',
                    background: 'rgba(227, 252, 236, 0.5)'
                },
                unhealthy: {
                    border: 'rgb(249,172,170)',
                    background: 'rgb(252,235,234, 0.5)',
                }
            },
            {
                healthy: {
                    border: 'rgb(158,229,255)',
                    background: 'rgba(227, 249, 252, 0.5)'
                },
                unhealthy: {
                    border: 'rgb(249,212,156)',
                    background: 'rgba(252,242,235,0.5)',
                }
            },
            {
                healthy: {
                    border: 'rgb(177,212,255)',
                    background: 'rgba(234,237,252,0.5)'
                },
                unhealthy: {
                    border: 'rgb(245,180,249)',
                    background: 'rgba(252,236,243,0.5)',
                }
            },
        ];

        let slice = this.props.checks;

        const agents = new Set(slice.map(c => c.agent));

        let chartData = {
            datasets: []
        };

        let i = 0;

        for (let agent of agents) {
            let colorSet;
            if (i < colorSets.length) {
                colorSet = colorSets[i];
            } else {
                colorSet = colorSets[0];
            }

            chartData.datasets.push({
                type: 'line',
                label: 'Healthy response time (ms) for ' + agent,
                data: slice
                    .filter(c => c.agent === agent)
                    .map((c) => {
                        return {
                            x: new Date(c.started_at),
                            y: c.health === 'HEALTHY' ? c.duration / 1000000 : null
                        }
                    }),
                borderWidth: 1,
                fill: true,
                spanGaps: false,
                borderColor: colorSet.healthy.border,
                backgroundColor: colorSet.healthy.background,
            });
            chartData.datasets.push({
                type: 'line',
                label: 'Unhealthy Response time (ms) from ' + agent,
                data: slice
                    .filter(c => c.agent === agent)
                    .map((c) => {
                        return {
                            x: new Date(c.started_at),
                            y: c.health !== 'HEALTHY' ? c.duration / 1000000 : null
                        }
                    }),
                borderWidth: 1,
                fill: true,
                spanGaps: false,
                borderColor: colorSet.unhealthy.border,
                backgroundColor: colorSet.unhealthy.background,
            });

            i++;
        }

        let chartOptions = {
            scales: {
                yAxes: [{
                    type: 'linear',
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        callback: function (value, index, values) {
                            if (value >= 1000) {
                                return (value / 1000) + 's'
                            }
                            return value + ' ms';
                        }
                    }
                }],
                xAxes: [{
                    type: 'time',
                    barPercentage: 1,
                    gridLines: {
                        offsetGridLines: true,
                        display: false
                    }
                }]
            },
            legend: false,
        };

        if (this.props.minimal === true) {
            chartOptions.scales.yAxes[0].display = false;
            chartOptions.scales.xAxes[0].display = false;
            chartOptions.tooltips = {enabled: false};
        }

        return (
            <div>
                <LineChart data={chartData} options={chartOptions} height={this.props.height} />
            </div>
        );
    }
}

export default ResponseTimeLineChart;
