import React, { Component } from "react";
import LoadingScreen from "../components/LoadingScreen";
import Header from "../components/Header";

import { connect } from "react-redux"
import { bindActionCreators} from 'redux'
import { loadUser } from "../store/actions/actions.js"
import Footer from "../components/Footer";
import RevealOnClickText from "../components/RevealOnClickText";

class Account extends Component {

    componentDidMount () {
        this.props.loadUser(this.props.authToken);
    }

    render() {

        if (this.props.userIsFetching === false || this.props.user === null) {
            return <LoadingScreen/>;
        }

        return (
            <div>
                <Header/>

                <div className="container mx-auto pt-6 text-center max-w-md px-4">

                    <h1 className="text-3xl font-light text-center py-8">Account</h1>

                    <p className="pb-8">
                        <span className="p-2 px-4 text-white font-bold bg-pink rounded-full">
                          {this.props.user.email}
                        </span>
                    </p>

                    <div className="py-8 my-8 border-t">
                        <h2 className="text-2xl font-normal text-grey-darker pb-8">Login</h2>
                        <p className="text-grey-darker leading-loose">
                            You've logged in using OAuth from a third party login provider. They control your username,
                            password, etc.
                        </p>
                    </div>

                    <div className="py-8 my-8 border-t">
                        <h2 className="text-xl font-normal text-grey-darker pb-8">API</h2>
                        <p className="text-grey-darker mb-8 leading-loose">
                            Here are your API credentials for your user <strong>{this.props.user.email}</strong>
                        </p>

                        <div className="my-8">
                            <label className="block text-grey-darker text-sm font-bold mb-2">
                                API User ID
                            </label>
                            <p className="py-2">
                            <span className="bg-grey-light text-grey-darker font-mono py-2 px-4 rounded-full text-xs md:text-sm">
                                {this.props.user ? this.props.user.id : ''}
                            </span>
                            </p>
                        </div>

                        <div className="my-8">
                            <label className="block text-grey-darker text-sm font-bold mb-2">
                                API Token
                            </label>
                            <p className="py-2">
                            <span className="bg-grey-light text-grey-darker font-mono py-2 px-4 rounded-full text-xs md:text-sm">
                                <RevealOnClickText text={this.props.authToken ? this.props.authToken : ''} />
                            </span>
                            </p>
                        </div>
                    </div>
                </div>

                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authToken: state.authToken,
        userIsFetching: state.userIsFetching,
        user: state.user
    }
}

const mapActionsToProps = (dispatch)  => {
    return bindActionCreators({ loadUser }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(Account);
