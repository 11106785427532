import React from 'react';
import config from '../services/Config.js';
import {NavLink} from "react-router-dom";
import CheckDefinitionListBlock from '../components/CheckDefinitionListBlock';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Header from "../components/Header";
import LoadingScreen from "../components/LoadingScreen";
import Footer from '../components/Footer'
import { PrimaryButton } from '../components/Buttons'

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        let c = this;
        c.state = {
            definitions: [],
            loadingDefinitions: true
        };

        fetch(config.API_V1_BASE_URI + '/api/v1/check-definition?workspace_id='+this.props.match.params.workspace, {
            headers: {
                'X-Auth': c.props.auth.getToken()
            }
          })
            .then((response) => {
                if (response.status === 200) {
                    return response;
                }

                if (response.status === 401) {
                    c.props.auth.logout();
                }

                alert('Sorry, something went wrong');
            })
            .then((r) => r.json())
            .then((json) => {
                if (json.status !== 'success') {
                    alert('Sorry, something went wrong');
                    return;
                }
                c.setState({definitions: json.data.checks, loadingDefinitions: false});
            });
    }

    render() {

        let c = this;

        if (!this.state.loadingDefinitions && this.state.definitions.length < 1) {
            return (
                <div className="min-h-screen">
                    <Header/>
                    <div className="text-center p-8 mt-8">
                        <p className="p-4 text-grey-darker">This workspace is empty</p>
                        <p className="p-4 text-grey-darkest">You haven't created any checks definitions yet</p>
                        <p className="mt-8">
                            <NavLink to={"/workspace/"+c.props.match.params.workspace+"/check-definition/create"}>
                                <PrimaryButton>
                                  <FontAwesomeIcon icon="pencil-alt"/>
                                  <span className="pl-4">
                                    Create new check
                                  </span>
                                </PrimaryButton>
                            </NavLink>
                        </p>
                    </div>
                    <div><br/><br/><br/><br/></div>
                    <Footer/>
                </div>
            );
        }

        const definitionList = this.state.loadingDefinitions ? <LoadingScreen/> : this.state.definitions.map(function (definition) {
            return <div className="sm:inline-block sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 py-8 sm:px-4 lg:px-6" key={definition.id}>
              <CheckDefinitionListBlock definition={definition} autorefresh={true} />
            </div>
        });

        return (
            <div>
                <Header/>
                <div className="min-h-screen p-2">
                    {definitionList}
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Dashboard;
