import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faAngleDown,
    faCircleNotch,
    faCheck,
    faCog,
    faExclamationTriangle,
    faGlobeAmericas,
    faHeart,
    faHeartbeat,
    faHome,
    faMapMarker,
    faPencilAlt,
    faPlusSquare,
    faSpinner,
    faSync,
    faThumbsDown,
    faThumbsUp,
    faTrashAlt,
    faUser,
    faUserAlt,
    faSlidersH,
    faSignOutAlt,
    faCross,
    faTimes,
    faEnvelope,
    faStopwatch,
    faCalendarCheck,
    faNetworkWired,
    faAngleRight,
    faAngleLeft,
    faWrench
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faThumbsUp,
    faThumbsDown,
    faCircleNotch,
    faCheck,
    faSpinner,
    faHeart,
    faHeartbeat,
    faExclamationTriangle,
    faUser,
    faUserAlt,
    faPencilAlt,
    faSync,
    faAngleDown,
    faTrashAlt,
    faPlusSquare,
    faHome,
    faGlobeAmericas,
    faMapMarker,
    faCog,
    faSlidersH,
    faSignOutAlt,
    faCross,
    faTimes,
    faEnvelope,
    faStopwatch,
    faCalendarCheck,
    faNetworkWired,
    faAngleRight,
    faAngleLeft,
    faWrench
);
