import React, { Component } from "react";
import {Redirect} from "react-router-dom";

class LoginRedirect extends Component {
    componentDidMount () {
        this._localStorage = window.localStorage;

        const url = this.props.location.pathname + this.props.location.search
        if (!url.includes('logout')) {
            this._localStorage.setItem('loginRedirect', url);
        }
    }

    render() {
        return (
            <Redirect to="/login" />
        );
    }
}

export default LoginRedirect;
