import React, {Component} from "react";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {loadWorkspaces} from '../store/actions/actions'
import {getWorkspace} from '../store/query/query.js';
import {Logo} from "./Logo";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayMobileNav: false
        };

        this.togglNav = this.togglNav.bind(this);
    }

    togglNav() {
        this.setState({
            displayMobileNav: !this.state.displayMobileNav
        })
    }

    componentDidMount () {
        if (!this.props.activeWorkspace && !this.props.workspacesIsFetching) {
            this.props.loadWorkspaces(this.props.authToken)
        }
    }

    render() {
        let classes = [
            "my-3",
            "no-underline",
            "text-grey-darker",
            "hover:text-black",
            "text-sm",
            "mx-4",
            "rounded-full",
            "px-4",
            "py-2",
            "xl:inline-block"
        ].join(" ");

        if (this.state.displayMobileNav) {
            classes += " block";
        } else {
            classes += " hidden";
        }

        const activeClasses = [
            'text-grey-darkest',
            'bg-grey-light'
        ].join(" ");

        if (!this.props.activeWorkspace) {
            return (
              <div className="border-b">
                  <div className="p-2 border-t-2 border-pink">
                      <div className="xl:flex justify-between">
                          <div>
                              <NavLink to="/">
                                  <span className="pl-4 pr-4 xl:pr-8 py-4 inline-block mr-4">
                                    <Logo/>
                                  </span>
                              </NavLink>

                              <button
                                  className="float-right mr-8 bg-grey-light text-grey-darkest rounded-full my-2 px-4 py-2 xl:hidden"
                                  onClick={this.togglNav}>&#9776;</button>

                              <NavLink className={classes} to={"/workspaces"} activeClassName={activeClasses}>
                                  <FontAwesomeIcon icon="map-marker" className="mr-2"/>
                                  <span className="mr-2">Workspaces</span>
                              </NavLink>
                          </div>
                          <div>
                              <NavLink className={classes} to={"/account"} activeClassName={activeClasses}
                                       title="My Account">
                                  <FontAwesomeIcon icon="wrench" className="xl:hidden mr-2"/>
                                  Account
                                  <FontAwesomeIcon icon="wrench" className="hidden xl:inline ml-2"/>
                              </NavLink>
                              <NavLink className={classes} to={"/logout"} activeClassName={activeClasses}
                                       title="Logout">
                                  <FontAwesomeIcon icon="sign-out-alt" className="xl:hidden mr-2"/>
                                  Logout
                                  <FontAwesomeIcon icon="sign-out-alt" className="hidden xl:inline mx-2"/>
                              </NavLink>
                          </div>
                      </div>
                  </div>
              </div>
            );
        }

        return (
            <div className="border-b">
                <div className="p-2 border-t-2 border-pink">
                    <div className="xl:flex justify-between">
                        <div className="xl:flex xl:flex-no-wrap">
                            <NavLink to="/">
                                <span className="font-bold pl-4 pr-4 xl:pr-8 py-4 inline-block mr-4 text-black">Whoops.io <sup className="bg-pink text-white rounded-full p-1">Beta</sup></span>
                            </NavLink>

                            <button className="float-right mr-8 bg-grey-light text-grey-darkest rounded-full my-2 px-4 py-2 xl:hidden" onClick={this.togglNav}>&#9776;</button>

                            <NavLink className={classes} to={"/workspaces"} activeClassName={activeClasses}>
                                <FontAwesomeIcon icon="map-marker" className="mr-2" />
                                <span className="mr-2">{this.props.activeWorkspace.name}</span>
                                <FontAwesomeIcon icon="angle-down" className="hidden xl:inline" />
                            </NavLink>
                            <NavLink className={classes} to={"/workspace/"+this.props.activeWorkspaceId+"/dashboard"} activeClassName={activeClasses}
                                     isActive={(m, l) => l.pathname.includes('/detail/') || l.pathname.includes('/dashboard')}>
                                <FontAwesomeIcon icon="home" className="mr-2"/>
                                Dashboard
                            </NavLink>
                            <NavLink className={classes} to={"/workspace/"+this.props.activeWorkspaceId+"/check-definition/create"} activeClassName={activeClasses}>
                                <FontAwesomeIcon icon="globe-americas" className="mr-2"/>
                                Create new check
                            </NavLink>
                            <NavLink className={classes} to={"/workspace/"+this.props.activeWorkspaceId+"/settings"} activeClassName={activeClasses}>
                                <FontAwesomeIcon icon="sliders-h" className="mr-2"/>
                                Settings
                            </NavLink>
                        </div>
                        <div className="xl:flex xl:flex-no-wrap">
                            <NavLink className={classes} to={"/account"} activeClassName={activeClasses} title="My Account">
                                <FontAwesomeIcon icon="wrench" className="xl:hidden mr-2"/>
                                Account
                                <FontAwesomeIcon icon="wrench" className="hidden xl:inline ml-2"/>
                            </NavLink>
                            <NavLink className={classes} to={"/logout"} activeClassName={activeClasses} title="Logout">
                                <FontAwesomeIcon icon="sign-out-alt" className="xl:hidden mr-2"/>
                                Logout
                                <FontAwesomeIcon icon="sign-out-alt" className="hidden xl:inline mx-2"/>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authToken: state.authToken,
        activeWorkspace: state.activeWorkspaceId
          ? getWorkspace(state.workspaces,  state.activeWorkspaceId)
          : null,
        activeWorkspaceId: state.activeWorkspaceId
    }
}

const mapActionsToProps = (dispatch)  => {
    return bindActionCreators({ loadWorkspaces }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(Header);
