export function getWorkspace(workspaces, id) {
  for (let i = 0; i < workspaces.length; i++) {
    const w = workspaces[i]
    if (w.id === id) {
      return w;
    }
  }

  return null;
}

export function getChecks(state, definitionId, limit, excludeAgents) {
  if (!state.checks[definitionId]) {
    return []
  }

  // Always sort results newest-first
  let checks = state.checks[definitionId].list.sort((a, b) => new Date(b.started_at).getTime() - new Date(a.started_at).getTime())

  if (excludeAgents && excludeAgents.size > 0) {
    checks = checks.filter(c => !excludeAgents.has(c.agent))
  }

  if (limit) {
    checks = checks.slice(0, limit)
  }

  return checks
}

export function isChecksLoaded(state, definitionId, from, to) {
  if (!state.checks[definitionId]) {
    return false
  }

  return state.checks[definitionId].loaded
}

export function isChecksFetching(state, definitionId, from, to) {
  if (!state.checks[definitionId]) {
    return false
  }

  return state.checks[definitionId].fetching
}
