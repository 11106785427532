import React, { Component } from "react";
import {NavLink} from "react-router-dom";

class NotFound extends Component {
    render() {
        return (
            <div className="h-screen">
                <div className="container mx-auto pt-6 max-w-sm">
                    <div className="shadow p-8 bg-grey-lightest mt-6 text-center ">

                        <h3 className="mb-8">Whoops! Page not found</h3>

                        <p>
                            The URL you've landed on doesn't exist
                        </p>

                        <hr/>

                        <div className="mt-8">
                            <NavLink className="bg-blue hover:bg-blue-light text-white font-bold py-2 px-4 border-b-4 border-blue-dark hover:border-blue rounded no-underline" to="/">
                                Take me somewhere safe
                            </NavLink>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound;
