import React, { Component } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { PrimaryButton } from '../components/Buttons'
import config from '../services/Config'
import { NavLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {loadUser, loadWorkspaces} from '../store/actions/actions'
import { connect } from 'react-redux'
import { getWorkspace } from '../store/query/query'
import InviteLink from '../components/InviteLink'
import NotificationRule from '../components/NotificationRule'
import NotificationRuleCreator from '../components/NotificationRuleCreator'
import RevealOnClickText from "../components/RevealOnClickText";

class Settings extends Component {
  constructor (props) {
    super(props)

    this.state = {
      plan: 'PRO',
      workspace: {
        name: ''
      },
      invite_code: null,
      loading: true,
      notificationRules: [],
      users: []
    }

    this.deleteRule = this.deleteRule.bind(this);
    this.onNotificationRuleCreated = this.onNotificationRuleCreated.bind(this);
  }

  componentDidMount () {
    this.loadSubscription();
    this.loadNotificationRules();
    this.loadWorkspaceAssignments();

    if (!this.props.user) {
      this.props.loadUser(this.props.token)
    }

    if (getWorkspace(this.props.workspaces, this.props.workspace)) {
      this.props.loadWorkspace();
    }
  }

  loadSubscription () {
    fetch(config.API_V1_BASE_URI + '/api/v1/workspace/' + this.props.match.params.workspace + '/subscription', {
      headers: {
        'X-Auth': this.props.auth.getToken()
      }
    }).then((r) => r.json())
      .then((json) => {
        this.setState({plan: json.data.subscription.plan})
      })
  }

  loadWorkspaceAssignments () {
    fetch(config.API_V1_BASE_URI + '/api/v1/user?assigned_workspace=' + this.props.match.params.workspace, {
      headers: {
        'X-Auth': this.props.auth.getToken()
      }
    }).then((r) => r.json())
      .then((json) => {
        this.setState({users: json.data.users})
      })
  }

  loadNotificationRules() {
    fetch(config.API_V1_BASE_URI + '/api/v1/workspace/' + this.props.match.params.workspace + '/notification-rule', {
      headers: {
        'X-Auth': this.props.auth.getToken()
      }
    }).then((r) => r.json())
      .then((json) => {
        this.setState({notificationRules: json.data.rules})
      })
  }

  onNotificationRuleCreated () {
    this.loadNotificationRules();
  }

  deleteRule(id) {
    fetch(config.API_V1_BASE_URI + '/api/v1/workspace/' + this.props.match.params.workspace + '/notification-rule/' + id, {
      method: 'DELETE',
      headers: {
        'X-Auth': this.props.auth.getToken(),
      }
    }).then((r) => {
      if (r.status === 200) {
        this.loadNotificationRules();
        return;
      }

      if (r.status === 401) {
        this.props.auth.logout();
        return;
      }

      r.json().then(json => {
        alert(json.data.errors[0].message);
      });
    });
  }

  render () {
    let currentSubscriptionText = '';
    switch (this.state.plan) {
      case 'FREE':
        currentSubscriptionText = (
          <p className="text-grey-dark pb-8 text-center">
            You're currently on the <strong>Free Plan</strong>
          </p>
        );
        break;

      case 'PRO':
        currentSubscriptionText = (
          <div>
            <p className="text-grey-dark pb-8 text-center">
              You're currently on the <strong>Pro Plan</strong>
            </p>
          </div>
        );
        break;
      default:
        break;
    }

    let notificationRules = this.state.notificationRules.map(r => {
      return <NotificationRule rule={r} key={r.id} deleteRule={this.deleteRule}/>
    });
    if (!notificationRules.length) {
      notificationRules = <p className="text-grey-dark">
        You don't have any notification rules setup yet
      </p>
    }

    const users = this.state.users.map(u => {
      return (
        <div key={u.id}>
          <p className="text-grey-darker mb-4 underline p-2">
            {u.nickname} &lt;{u.email}&gt;
          </p>
        </div>
      );
    });

    const workspace = getWorkspace(this.props.workspaces, this.props.match.params.workspace);

    return (
      <div className="h-screen">
        <Header/>

        <div className="container mx-auto pt-6 text-center max-w-md px-4">

          <h1 className="text-3xl font-light text-center py-8">Settings</h1>

          <p className="pb-8">
            <span className="p-2 px-4 text-white font-bold bg-pink rounded-full">
              {workspace !== null ? workspace.name : '...'}
            </span>
          </p>

          <div className="py-8 my-8 border-t">
            <h2 className="text-xl font-normal text-grey-darker pb-8">Plan</h2>
            {currentSubscriptionText}
            <p>
              <NavLink to={"/workspace/" + this.props.match.params.workspace + "/upgrade"}>
                <PrimaryButton>Change plan or payment method</PrimaryButton>
              </NavLink>
            </p>
          </div>

          <div className="py-8 my-8 border-t">
            <h2 className="text-xl font-normal text-grey-darker pb-8">Team</h2>
            {users}

            <InviteLink parent={this} workspaceId={this.props.match.params.workspace} auth={this.props.auth} />
          </div>

          <div className="py-8 my-8 border-t">
            <h2 className="text-xl font-normal text-grey-darker pb-8">Notifications</h2>
            <p className="text-grey-darker mb-8 leading-loose">
              How should we alert you when your monitors go up or down
            </p>
            <div className="">
              {notificationRules}
            </div>

            <NotificationRuleCreator workspace={this.props.match.params.workspace} onCreated={this.onNotificationRuleCreated} auth={this.props.auth} />
          </div>

          <div className="py-8 my-8 border-t">
            <h2 className="text-xl font-normal text-grey-darker pb-8">API</h2>
            {!this.props.user || !workspace ? <p>...</p> : <p className="text-grey-darker mb-8 leading-loose">
              Here are your API credentials for <strong>{this.props.user.email}</strong>
            </p>}

            <div className="my-6">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                Workspace ID
              </label>
              <p className="py-2">
                <span className="bg-grey-light text-grey-darker font-mono py-2 px-4 rounded-full text-xs md:text-sm">
                    {workspace ? workspace.id : ''}
                </span>
              </p>
            </div>

            <div className="my-6">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                API User ID
              </label>
              <p className="py-2">
                <span className="bg-grey-light text-grey-darker font-mono py-2 px-4 rounded-full text-xs md:text-sm">
                    {this.props.user ? this.props.user.id : ''}
                </span>
              </p>
            </div>

            <div className="my-8">
              <label className="block text-grey-darker text-sm font-bold mb-2">
                API Token
              </label>
              <p className="py-2">
                <span className="bg-grey-light text-grey-darker font-mono py-2 px-4 rounded-full text-xs md:text-sm">
                    <RevealOnClickText text={this.props.token ? this.props.token : ''} />
                </span>
              </p>
            </div>

          </div>
        </div>

        <div><br/><br/><br/><br/></div>

        <Footer/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    workspaces: state.workspaces,
    user: state.user,
    token: state.authToken
  }
}

const mapActionsToProps = (dispatch)  => {
  return bindActionCreators({ loadWorkspaces, loadUser }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(Settings);
