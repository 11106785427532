import React, { Component } from "react";
import {NavLink, Redirect} from "react-router-dom";
import { DangerButton, LinkButton, PrimaryButton } from '../components/Buttons'
import {TextInput} from "../components/FormInput";
import Header from "../components/Header";
import Footer from '../components/Footer'
import config from '../services/Config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class CheckDefinitionEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: 'loading',
            unhealthyThreshold: '',
            healthyThreshold: '',
            definition: {},
        };

        this.loadDefinition();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit= this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleDelete() {
        if (!this.state.definition) {
            return;
        }

        fetch(config.API_V1_BASE_URI + '/api/v1/check-definition/' + this.state.definition.id, {
            method: 'DELETE',
            headers: {
                'X-Auth': this.props.auth.getToken()
            }
        }).then((r) => r.json())
          .then(() => {
              this.setState({mode: 'deleted'});
              alert('Your check was successfully deleted');
          });
    }

    loadDefinition() {
        fetch(config.API_V1_BASE_URI + '/api/v1/check-definition/' + this.props.match.params.id, {
            headers: {
                'X-Auth': this.props.auth.getToken()
            }
        }).then((r) => r.json())
          .then((json) => {
              this.setState({
                  definition: json.data.definition,
                  unhealthyThreshold: json.data.definition.unhealthy_threshold,
                  healthyThreshold: json.data.definition.healthy_threshold,
                  intervalSeconds: json.data.definition.interval.count,
                  mode: 'form'
              })
          })
    }

    handleSubmit(event) {
        event.preventDefault();
        fetch(config.API_V1_BASE_URI + '/api/v1/check-definition/' + this.state.definition.id, {
            method: 'PUT',
            headers: {
                'X-Auth': this.props.auth.getToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                healthy_threshold: this.state.healthyThreshold,
                unhealthy_threshold: this.state.unhealthyThreshold,
                interval: this.state.intervalSeconds
            })
        }).then(response => {
            if (response.status === 200) {
                response.json().then(json => {
                    this.setState({ mode: 'success' });
                });
                return;
            }

            if (response.status === 401) {
                this.props.auth.logout();
                return;
            }

            response.json().then(json => {
                alert(json.data.errors[0].message);
            });
        });
    }

    render() {

        if (this.state.mode === 'loading') {
            return (
                <div>
                    <Header/>

                    <div className="container max-w-lg mx-auto my-8 px-4">
                        <div className="text-center text-xl text-grey-dark p-8">
                            <FontAwesomeIcon icon="circle-notch" spin={true} />
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.mode === 'success') {
            return (
                <Redirect to={`/workspace/${this.props.match.params.workspace}/detail/${this.props.match.params.id}`} />
            )
        }

        if (this.state.mode === 'deleted') {
            return (
                <Redirect to={`/workspace/${this.props.match.params.workspace}/dashboard`} />
            )
        }

        let label = this.state.definition.host;
        if (this.state.definition.uri) {
            label = this.state.definition.uri;
        }

        return (
            <div>
                <Header/>

                <div className="container max-w-lg mx-auto my-8 px-4">

                    <h1 className="text-4xl font-light text-center py-8 mb-8 overflow-hidden w-full leading-loose" style={{textOverflow: 'ellipsis'}}>
                        Editing
                        &nbsp;

                        <span className="text-xl block md:inline mt-4 md:text-4xl text-grey-darker">
                            {label}
                        </span>
                    </h1>

                    <form onSubmit={this.handleSubmit}>

                        <div className="mb-8">
                            <label className="block text-grey-darker text-sm font-bold mb-2">
                                Check Interval
                            </label>
                            <div className="flex">
                                <div>
                                    <p className="text-grey py-4 pr-4">
                                        Every
                                    </p>
                                </div>
                                <div>
                                    <TextInput type="number"
                                               onChange={this.handleChange}
                                               value={this.state.intervalSeconds}
                                               name="intervalSeconds"
                                               className="text-black"
                                               min={1}
                                               max={3600}
                                               required={true} />
                                </div>
                                <div>
                                    <p className="text-grey py-4 pl-4">
                                        {
                                            parseInt(this.state.intervalSeconds, 10) === 1
                                                ? "second"
                                                : "seconds"
                                        }
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className="md:flex mt-8 pt-4">
                            <div className="md:w-1/2 mb-4">
                                <div className="md:pr-4">
                                    <label className="block text-grey-darker text-sm font-bold mb-2">
                                        Unhealthy Threshold
                                    </label>
                                    <TextInput type="number"
                                               onChange={this.handleChange}
                                               value={this.state.unhealthyThreshold}
                                               name="unhealthyThreshold"
                                               className="text-red"
                                               min={1}
                                               max={5}
                                               required={true} />
                                    <p className="text-grey py-4">
                                        How many unhealthy checks in a row before we trigger an alert
                                    </p>
                                </div>
                            </div>
                            <div className="md:w-1/2">
                                <div className="md:pl-4 mb-4">
                                    <label className="block text-grey-darker text-sm font-bold mb-2">
                                        Healthy Threshold
                                    </label>
                                    <TextInput type="number"
                                               onChange={this.handleChange}
                                               value={this.state.healthyThreshold}
                                               name="healthyThreshold"
                                               className="text-green"
                                               min={1}
                                               max={5}
                                               required={true} />
                                    <p className="text-grey py-4">
                                        How many healthy checks in a row before we trigger an alert
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="md:flex md:flex-row-reverse mb-4">
                            <div className="md:w-3/10 mb-4">
                                <div className="md:pl-4">
                                    <PrimaryButton className="block w-full">Save</PrimaryButton>
                                </div>
                            </div>
                            <div className="md:w-1/5 mb-4">
                                <NavLink to={`/workspace/${this.props.match.params.workspace}/detail/${this.props.match.params.id}`}
                                         className="no-underline md:text-right">
                                    <LinkButton type="button" className="block w-full md:inline md:w-auto md:float-right">Cancel</LinkButton>
                                </NavLink>
                            </div>
                            <div className="md:w-1/2 mb-4"></div>
                        </div>
                    </form>

                    <div className="my-8 border-t py-8 text-right">
                        <DangerButton className="block w-full md:inline md:w-auto" onClick={() => window.confirm('Are you sure?') ? this.handleDelete() : false }>Delete this check definition</DangerButton>
                    </div>
                </div>

                <div><br/><br/><br/><br/></div>

                <Footer/>
            </div>
        );
    }
}

export default CheckDefinitionEdit;
