import React from 'react'
import { Route } from 'react-router-dom'
import PageviewTracker from './PageviewTracker'

class WhoopsRoute extends React.Component {
  createComponentWithProps(component, ...props) {
    const finalProps = Object.assign({}, ...props);
    return (
      React.createElement(component, finalProps)
    );
  }

  render() {
    const {component, ...props} = this.props;
    return (
      <Route {...props} render={routeProps => {
        return <PageviewTracker component={this.createComponentWithProps(component, routeProps, props)} {...routeProps} />;
      }}/>
    );
  }
}

export default WhoopsRoute;
