import config from '../../services/Config.js';

export const ENABLE_AGENT = 'ENABLE_AGENT';
export const DISABLE_AGENT = 'DISABLE_AGENT';
export const RECEIVE_CHECKS = 'RECEIVE_CHECKS';
export const LOADING_CHECKS = 'LOADING_CHECKS';
export const RECEIVE_WORKSPACES = 'RECEIVE_WORKSPACES';
export const LOADING_WORKSPACES = "LOADING_WORKSPACES";
export const LOADING_USER = "LOADING_USER";
export const RECEIVE_USER = "RECEIVE_USER";
export const SET_ROUTE = "SET_ROUTE";
export const SET_ACTIVE_WORKSPACE = "SET_ACTIVE_WORKSPACE";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";

export function loadWorkspaces(token) {
  return function (dispatch, getState) {

    const state = getState()
    if (state.workspacesIsFetching === true) {
      return;
    }

    dispatch(loadingWorkspaces())

    const ops = {
      headers: {
        'X-Auth': token
      }
    }
    return fetch(config.API_V1_BASE_URI + '/api/v1/workspace', ops)
      .then(
        response => response.json(),
        error => console.log('An error occurred fetching from the API', error)
      ).then(
        json => dispatch(receiveWorkspaces(json.data.workspaces))
      )
  }
}

export function loadUser(token) {
  return function (dispatch, getState) {

    const state = getState()
    if (state.userIsFetching === true) {
      return;
    }

    dispatch(loadingUser())

    const ops = {
      headers: {
        'X-Auth': token
      }
    }
    return fetch(config.API_V1_BASE_URI + '/api/v1/me', ops)
      .then(
        response => response.json(),
        error => console.log('An error occurred fetching from the API', error)
      ).then(
        json => dispatch(receiveUser(json.data.user))
      )
  }
}

export function loadChecks(token, definitionId, from, to, limit) {
  return function (dispatch) {
    dispatch(loadingChecks(definitionId))

    const ops = {
      headers: {
        'X-Auth': token
      }
    }

    let uri = `${config.API_V1_BASE_URI}/api/v1/check?definition_id=${definitionId}`;

    if (limit) {
      uri += `&limit=${limit}&sort_direction=DESC`;
    }

    if (from instanceof Date) {
      uri += `&started_after=${from.toISOString()}`;
    }

    if (to instanceof Date) {
      uri += `&started_before=${to.toISOString()}`;
    }

    fetch(uri, ops)
      .then(
        response => response.json(),
        error => console.log('An error occurred fetching from the API', error)
      ).then(
        json => dispatch(receiveChecks(definitionId, json.data.checks))
      )
  }
}

export function loadingUser() {
  return {
    type: LOADING_USER
  }
}


export function loadingWorkspaces() {
  return {
    type: LOADING_WORKSPACES
  }
}

export function loadingChecks(definitionId) {
  return {
    type: LOADING_CHECKS,
    definitionId: definitionId
  }
}

export function receiveWorkspaces(workspaces) {
  return {
    type: RECEIVE_WORKSPACES,
    workspaces: workspaces
  }
}

export function receiveUser(user) {
  return {
    type: RECEIVE_USER,
    user: user
  }
}

export function receiveChecks(definitionId, checks) {
  return {
    type: RECEIVE_CHECKS,
    definitionId: definitionId,
    checks: checks
  }
}

export function setAuthToken(token) {
  return {
    type: SET_AUTH_TOKEN,
    token: token
  }
}

export function setRoute(match, location) {
  return {
    type: SET_ROUTE,
    match: match,
    location: location
  }
}

export function setActiveWorkspace(workspaceId) {
  return {
    type: SET_ACTIVE_WORKSPACE,
    workspaceId: workspaceId
  }
}

export function disableAgent(agent) {
  return {
    type: DISABLE_AGENT,
    agent: agent
  }
}

export function enableAgent(agent) {
  return {
    type: ENABLE_AGENT,
    agent: agent
  }
}
