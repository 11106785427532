import config from "./Config";

/**
 * @deprecated
 */
class Auth {
    _localStorage;

    constructor(main) {
        this.main = main;
        this._localStorage = window.localStorage;

        // let params = new URLSearchParams(window.location.search);
        // if (params.get('api_key')) {
        //     this._localStorage.setItem('auth_token', params.get('api_key'));
        //
        //     const target = this._localStorage.getItem('loginRedirect');
        //
        //     this._localStorage.removeItem('loginRedirect');
        //     if (target) {
        //         window.location = target;
        //     } else {
        //         window.location = '/';
        //     }
        // }

        this.token = this._localStorage.getItem('auth_token');
    }

    recheckToken() {
        let auth = this;

        if (!auth.getToken()) {
            // No token is set. Don't bother checking if `null` is a valid token
            return;
        }

        this.checkToken(auth.token, function () {}, function () {
            auth.logout();
        });
    }

    checkToken(token, onSuccess, onFail) {
        fetch(config.API_V1_BASE_URI + '/api/v1/hello', {
            headers: {
                'X-Auth': token,
            },
        }).then(function (response) {
            if (response.status === 401) {
                onFail();
                return;
            }

            onSuccess();
        });
    }

    logout() {
        this.token = null;
        this._localStorage.removeItem('auth_token');
        this.main.forceUpdate();
    }

    login(token, onFail) {
        let auth = this;

        this.checkToken(token, function () {
            auth.token = token;
            auth._localStorage.setItem('auth_token', token);
            auth.main.forceUpdate();
        }, onFail);
    }

    getToken() {
        return this.token;
    }

    isLogged() {
        return this.token !== null;
    }
}

export default Auth;
