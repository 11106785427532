import React from "react";
import { Bar } from 'react-chartjs-2'

class BarChart extends React.Component {
    render () {
        return (
          <Bar height={this.props.height} options={this.props.options} data={this.props.data} />
        );
    }
}

export default BarChart;
