import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class DateTimeInput extends Component {
    monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    constructor(props) {
        super(props)
        const datetime = props.initialValue ? this.getPreset(props.initialValue) : new Date()
        this.state = {
            datetime: datetime,
            viewingDate: datetime,
            drop: false,
            date: datetime.getDate(),
            hour: datetime.getHours(),
            minute: datetime.getMinutes(),
            value: props.initialValue ? props.initialValue : '15m'
        }
        this.toggleDropdown = this.toggleDropdown.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.pickViewingDayOfMonth = this.pickViewingDayOfMonth.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount () {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    toggleDropdown() {
        this.setState((prevState) => {
            return {
                drop: !prevState.drop
            }
        })
    }

    getPreset(string) {
        const now = new Date();
        if (string === "now") {
            return now;
        }
        switch (string.slice(-1)) {
            case 'm':
                now.setMinutes(now.getMinutes() - parseInt(string.slice(0, -1), 10))
                return now;
            case 'h':
                now.setHours(now.getHours() - parseInt(string.slice(0, -1), 10))
                return now;
            case 'd':
                now.setDate(now.getDate() - parseInt(string.slice(0, -1), 10))
                return now
            default:
                break;
        }
        switch (string.slice(-2)) {
            case 'mo':
                now.setMonth(now.getMonth() - parseInt(string.slice(0, -2), 10))
                return now
            default:
                break;
        }
        return string;
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

        this.updateDatetime()
    }
    pickViewingDayOfMonth(dayNumber) {
        this.setState({date: dayNumber}, () => {
            this.updateDatetime(this.state.viewingDate.getMonth(), this.state.viewingDate.getFullYear())
            this.toggleDropdown()
        })
    }

    updateDatetime(month = null, year = null, value = null) {
        this.setState((prevState) => {
            const updated = new Date(
              year !== null ? year : prevState.datetime.getFullYear(),
              month !== null ? month : prevState.datetime.getMonth(),
              prevState.date,
              prevState.hour,
              prevState.minute
            )
            if (this.props.onDatetimeChange) {
                this.props.onDatetimeChange(function () {
                    return updated;
                })
            }
            return {
                datetime: updated,
                value: value === null ? updated.toLocaleString() : value
            }
        })
    }

    getValueText(string) {
        if (string === "now") {
            return "Now"
        }
        switch (string.slice(-1)) {
            case 'm':
                return string.slice(0, -1) + (string.slice(0, -1) === "1" ? ' min ago' : ' mins ago')
            case 'h':
                return string.slice(0, -1) + (string.slice(0, -1) === "1" ? ' hour ago' : ' hours ago')
            case 'd':
                return string.slice(0, -1) + (string.slice(0, -1) === "1" ? ' day ago' : ' days ago')
            default:
                break;
        }

        switch (string.slice(-2)) {
            case 'mo':
                return string.slice(0, -2) +  (string.slice(0, -2) === "1" ? ' month ago' : ' months ago')
            default:
                break;
        }

        return string;
    }

    pickValue(string) {
        const datetime = this.getPreset(string)
        this.setState({hour: datetime.getHours(), minute: datetime.getMinutes(), date: datetime.getDate(), month: datetime.getMonth(), year: datetime.getFullYear(), viewingDate: datetime, datetime: datetime});
        this.updateDatetime(null, null, string)
    }

    handleClickOutside(event) {
        if (!this.dropdownRef || !this.valueRef) {
            return;
        }

        if (!this.dropdownRef.contains(event.target) && !this.valueRef.contains(event.target)) {
            this.setState({drop: false})
        }
    }

    render() {

        const viewingDate = this.state.viewingDate;
        const daysInViewingMonth = new Date(viewingDate.getFullYear(), viewingDate.getMonth()+1, 0).getDate()

        return (
            <div className="">
                <div className={"py-2 text-center cursor-pointer text-grey-darkest hover:text-blue-dark " + (this.state.drop ? "text-blue-dark" : "")} style={{whiteSpace: 'nowrap'}} onClick={this.toggleDropdown} ref={(e) => this.valueRef = e}>
                    {this.getValueText(this.state.value)}
                </div>

                {this.state.drop === false ? null :
                  <div className="bg-white rounded-lg p-2 mt-1 absolute align-bottom z-50 mt-2 md:flex" style={{left: 0}} ref={(e) => this.dropdownRef = e}>

                      <div className="p-1 md:w-1/2 lg:w-1/3">
                          <button className={"px-3 text-grey-darkest hover:text-black rounded inline-block w-1/2 md:w-full p-1 text-left " + ((this.state.value === 'now') ? ' bg-blue-lighter text-blue-dark' : '')} onClick={() => this.pickValue('now')}>Now</button>
                          <button className={"px-3 text-grey-darkest hover:text-black rounded inline-block w-1/2 md:w-full p-1 text-left " + ((this.state.value === '15m') ? ' bg-blue-lighter text-blue-dark' : '')} onClick={() => this.pickValue('15m')}>15 minutes ago</button>
                          <button className={"px-3 text-grey-darkest hover:text-black rounded inline-block w-1/2 md:w-full p-1 text-left " + ((this.state.value === '30m') ? ' bg-blue-lighter text-blue-dark' : '')} onClick={() => this.pickValue('30m')}>30 minutes ago</button>
                          <button className={"px-3 text-grey-darkest hover:text-black rounded inline-block w-1/2 md:w-full p-1 text-left " + ((this.state.value === '1h') ? ' bg-blue-lighter text-blue-dark' : '')} onClick={() => this.pickValue('1h')}>1 hour ago</button>
                          <button className={"px-3 text-grey-darkest hover:text-black rounded inline-block w-1/2 md:w-full p-1 text-left " + ((this.state.value === '3h') ? ' bg-blue-lighter text-blue-dark' : '')} onClick={() => this.pickValue('3h')}>3 hours ago</button>
                          <button className={"px-3 text-grey-darkest hover:text-black rounded inline-block w-1/2 md:w-full p-1 text-left " + ((this.state.value === '6h') ? ' bg-blue-lighter text-blue-dark' : '')} onClick={() => this.pickValue('6h')}>6 hours ago</button>
                          <button className={"px-3 text-grey-darkest hover:text-black rounded inline-block w-1/2 md:w-full p-1 text-left " + ((this.state.value === '12h') ? ' bg-blue-lighter text-blue-dark' : '')} onClick={() => this.pickValue('12h')}>12 hours ago</button>
                          <button className={"px-3 text-grey-darkest hover:text-black rounded inline-block w-1/2 md:w-full p-1 text-left " + ((this.state.value === '1d') ? ' bg-blue-lighter text-blue-dark' : '')} onClick={() => this.pickValue('1d')}>1 day ago</button>
                          <button className={"px-3 text-grey-darkest hover:text-black rounded inline-block w-1/2 md:w-full p-1 text-left " + ((this.state.value === '3d') ? ' bg-blue-lighter text-blue-dark' : '')} onClick={() => this.pickValue('3d')}>3 days ago</button>
                          <button className={"px-3 text-grey-darkest hover:text-black rounded inline-block w-1/2 md:w-full p-1 text-left " + ((this.state.value === '6d') ? ' bg-blue-lighter text-blue-dark' : '')} onClick={() => this.pickValue('6d')}>6 days ago</button>
                          <button className={"px-3 text-grey-darkest hover:text-black rounded inline-block w-1/2 md:w-full p-1 text-left " + ((this.state.value === '12d') ? ' bg-blue-lighter text-blue-dark' : '')} onClick={() => this.pickValue('12d')}>12 days ago</button>
                          <button className={"px-3 text-grey-darkest hover:text-black rounded inline-block w-1/2 md:w-full p-1 text-left " + ((this.state.value === '1mo') ? ' bg-blue-lighter text-blue-dark' : '')} onClick={() => this.pickValue('1mo')}>1 month ago</button>
                      </div>

                      <div className="md:w-1/2 lg:w-2/3 mt-2">
                          <div className="p-1 text-center">
                              <button className="p-2 bg-white float-left" onClick={() => {
                                  this.setState({
                                      viewingDate: new Date(this.state.viewingDate.getFullYear(), this.state.viewingDate.getMonth() - 1, 1)
                                  })
                              }}>
                                  <FontAwesomeIcon icon="angle-left" className="text-grey-dark"/>
                              </button>
                              <button className="p-2 bg-white float-right" onClick={() => {
                                  this.setState({
                                      viewingDate: new Date(this.state.viewingDate.getFullYear(), this.state.viewingDate.getMonth() + 1, 1)
                                  })
                              }}>
                                  <FontAwesomeIcon icon="angle-right" className="text-grey-dark"/>
                              </button>
                              <div className="p-2">
                                  {this.monthNames[viewingDate.getMonth()]} {this.state.viewingDate.getFullYear()}
                              </div>

                              <div className="clearfix"></div>
                          </div>
                          <div className="flex flex-wrap">
                              {Array.from(new Array(daysInViewingMonth).keys()).map(d => {
                                  const active = this.state.viewingDate.getFullYear() === this.state.datetime.getFullYear()
                                  && this.state.viewingDate.getMonth() === this.state.datetime.getMonth()
                                  && d+1 === this.state.datetime.getDate()
                                  return <div className="w-1/7" key={d+1}>
                                      <div className={"p-2 rounded text-center hover:text-blue cursor-pointer text-grey-darker " + (active ? "bg-blue-lighter text-blue-dark" : "")} onClick={() => this.pickViewingDayOfMonth(d+1)}>
                                          {d+1}
                                      </div>
                                  </div>
                              })}
                          </div>
                          <div className="flex justify-center text-center">
                              <div className="">
                                  <select value={this.state.hour} onChange={this.handleChange} name="hour" className="p-2 text-center text-grey-darkest bg-grey-lighter rounded-l-full">
                                      {Array.from(new Array(24).keys()).map( h => {
                                          return <option value={h}>
                                              {h}
                                          </option>
                                      })}
                                  </select>
                              </div>
                              <div className="p-2 bg-grey-lighter">
                                  <span>
                                      :
                                  </span>
                              </div>
                              <div className="">
                                  <select value={this.state.minute} onChange={this.handleChange} name="minute" className="p-2 text-center text-grey-darkest bg-grey-lighter rounded-r-full">
                                      {Array.from(new Array(60).keys()).map( m => {
                                          return <option value={m}>
                                              {m}
                                          </option>
                                      })}
                                  </select>
                              </div>
                          </div>
                      </div>

                </div>}
            </div>
        )
    }
}
