import {
  ENABLE_AGENT,
  DISABLE_AGENT,
  LOADING_CHECKS,
  LOADING_WORKSPACES, RECEIVE_CHECKS,
  RECEIVE_WORKSPACES,
  SET_AUTH_TOKEN, SET_ROUTE, LOADING_USER, RECEIVE_USER, SET_ACTIVE_WORKSPACE
} from '../actions/actions'
import {createBrowserHistory} from 'history'

let defaultState = {
  authToken: null,
  browserHistory: createBrowserHistory(),
  agentsDisabled: new Set(),
  checks: {},
  routeLocation: null,
  routeMatch: null,
  workspaces: [],
  workspacesLoaded: false,
  workspacesIsFetching: false,
  userIsFetching: false,
  user: null,
  activeWorkspaceId: null
}

function defaultCheckState() {
  return {
    list: [],
    loaded: false,
    fetching: false
  }
}

const reducers = (state = defaultState, action) => {
  let checks;

  switch (action.type) {
    case RECEIVE_WORKSPACES:
      return {
        ...state,
        workspaces: action.workspaces ? action.workspaces : [],
        workspacesIsFetching: false,
        workspacesLoaded: true,
      }
    case LOADING_WORKSPACES:
      return {
        ...state,
        workspacesIsFetching: true
      }
    case LOADING_USER:
      return {
        ...state,
        userIsFetching: true
      }
    case RECEIVE_USER:
      return {
        ...state,
        user: action.user
      }
    case RECEIVE_CHECKS:
      checks = state.checks;
      if (!checks[action.definitionId]) {
        checks[action.definitionId] = defaultCheckState()
      }
      checks[action.definitionId].list = action.checks;
      checks[action.definitionId].loaded = true;
      checks[action.definitionId].fetching = false;
      return {
        ...state,
        checks: checks
      }
    case LOADING_CHECKS:
      checks = state.checks;
      if (!checks[action.definitionId]) {
        checks[action.definitionId] = defaultCheckState()
      }
      checks[action.definitionId].fetching = true;
      return {
        ...state,
        checks: checks
      }
    case SET_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.token
      }
    case SET_ROUTE:
      return {
        ...state,
        routeMatch: action.match,
        routeLocation: action.location
      }
    case SET_ACTIVE_WORKSPACE:
      return {
        ...state,
        activeWorkspaceId: action.workspaceId
      }
    case DISABLE_AGENT:
      let afterDisable = state.agentsDisabled
      afterDisable.add(action.agent)
      return {
        ...state,
        disabledAgents: afterDisable
      }
    case ENABLE_AGENT:
      let afterEnable = state.agentsDisabled
      afterEnable.delete(action.agent)
      return {
        ...state,
        disabledAgents: afterEnable
      }
    default:
      return state;
  }
}

export default reducers;
