import React from 'react'
import { Analyst } from '../services/Analyst'

export class Timeline extends React.Component {
  constructor (props) {
    super(props)
    this.state = {view: 9}
  }
  render () {
    return (
      <div>

        <div className="pt-8">
          {this.props.checks.sort((a, b) => new Date(b.started_at).getTime() - new Date(a.started_at).getTime()).slice(0, this.state.view).map((c) => {

            let healthBadge = (
              <span
                className="bg-green-lighter rounded-full px-2 py-1 text-xs font-bold uppercase text-green-dark float-right">
                    Healthy
                </span>
            )
            if (c.health === 'UNHEALTHY') {
              healthBadge = (
                <span className="bg-red-lighter rounded-full px-2 py-1 text-xs font-bold uppercase text-red-dark float-right">
                        Unhealthy
                    </span>
              )
            }

            let connectionStatus = '';
            for (let i = 0; i < this.props.connectionStatuses.length; i++) {
              const status = this.props.connectionStatuses[i];
              if (status.id === c.connection_status) {
                connectionStatus = status.description;
              }
            }

            const httpStatusMessage = c.http_status_code
              ? <span>HTTP Status: <span className="mono">{c.http_status_code}</span></span>
              : ''

            return (
              <div key={c.id}>
                <div className="border-t-4">
                  <div className="flex">
                    <div className="w-3/4">

                      <div className="rounded bg-grey-light mr-8 -mt-6 py-2 px-4">
                        {healthBadge}

                        <p className="font-normal pt-1 pb-2 text-lg">
                          {Math.round(Analyst.ns2ms(c.duration) * 100) / 100}<span
                          className="text-sm">ms</span>
                        </p>

                        <span className="text-xs text-grey-dark font-mono">{c.agent}</span>

                        <div className="py-1 text-xs text-grey-darker pt-2">
                          <div className="flex">
                            <p className="w-1/2">
                              {connectionStatus}
                            </p>
                            <p className="w-1/2 text-right">
                              {httpStatusMessage}
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="w-1/4">
                      <div className="-mt-8 text-right">

                        <div className="border-r-4 mr-8">
                          <br/>
                        </div>

                        <span title={(new Date(c.started_at).toLocaleString())}>
                          <span
                            className="bg-grey-light rounded-full px-4 py-2 text-sm border-grey-lighter border-t-4 border-b-4">
                                        {String((new Date(c.started_at)).getHours()).padStart(2, 0)}
                            :
                            {String((new Date(c.started_at)).getMinutes()).padStart(2, 0)}
                            <span className="text-grey-dark text-xs">
                                            :
                              {String((new Date(c.started_at)).getSeconds()).padStart(2, 0)}
                                            </span>
                                    </span>
                        </span>

                        <div className="border-r-4 mr-8">
                          <br/><br/><br/><br/><br/>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}

          {this.state.view >= this.props.checks.length ? null : (
            <p className="text-right py-6 text-sm">
              <button className="cursor-pointer text-grey underline" onClick={() => this.setState((state) => {
                return {
                  ...state,
                  view: state.view + 9
                }
              })}>
                Load more
              </button>
            </p>
          )}
        </div>
      </div>
    )
  }
}
