const config = {
    API_V1_BASE_URI: 'https://api.whoops.io',
    AUTH_V1_BASE_URI: 'https://api.whoops.io',
    STRIPE_PUBLIC_KEY: 'pk_live_FCVFHpJHbpJhiG0ZxvpO9x4C',
    GOOGLE_ANALYTICS_ENABLED: true,
    // API_V1_BASE_URI: 'http://127.0.0.1',
    // AUTH_V1_BASE_URI: 'http://127.0.0.1',
    // STRIPE_PUBLIC_KEY: 'pk_test_iVXg6kJhFdlZx8jhV3UGcxfK',
    // GOOGLE_ANALYTICS_ENABLED: false,

    GOOGLE_ANALYTICS_TRACKING_CODE: 'UA-126406977-2'
};

export default config;
