import React from "react";
import BarChart from "./BarChart";
import {Analyst} from "../services/Analyst";

class AverageResponseTimeColumnChart extends React.Component {
    render() {

        if (this.props.checks.length < 1) {
            return '';
        }

        const slice = this.props.checks.slice().reverse();

        const ns2ms = 0.000001;

        let scale = ns2ms; // Scale 1 NS to 1 MS
        let max = Math.round(Math.max(...slice.map(c => c.duration)) * scale);
        while (max > 100) {
            scale /= 2;
            max = Math.round(Math.max(...slice.map(c => c.duration)) * scale);
        }

        let valuesHealthy = [];
        let valuesUnhealthy = [];
        let categories = [];

        for (let i = 0; i < max+1; i++) {
            categories.push(i);
            valuesHealthy[i] = 0;
            valuesUnhealthy[i] = 0;
        }

        for (let i = 0; i < slice.length; i++) {
            let category = Math.ceil(slice[i].duration * scale);
            if (slice[i].health === 'HEALTHY') {
                valuesHealthy[category]++;
            } else {
                valuesUnhealthy[category]++;
            }
        }

        const data = {
            labels: categories.map(i => Analyst.nanosecondsToHuman(i / scale)),
            datasets: [
                {
                    label: "Healthy Response Time (milliseconds)",
                    backgroundColor: "#a2f5bf",
                    data: /*[3, 1, 5]*/ valuesHealthy
                },
                {
                    label: "Unhealthy Response Time (milliseconds)",
                    backgroundColor: "#f9acaa",
                    data: /*[3, 1, 5]*/ valuesUnhealthy
                },
            ]
        };

        const options = {
            ticks: {
                min: 0,
                max: max+1
            },
            scales: {
                xAxes: [{
                    label: "Response time",
                    gridLines: {
                        display: false
                    }
                }],
                yAxes: [{
                    label: "Number of checks",
                    barPercentage: 1,
                    gridLines: {
                        display: false
                    }
                }]
            },
            legend: false
        };

        return <BarChart data={data} options={options} height={this.props.height} />
    }
}

export default AverageResponseTimeColumnChart;
