import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {NavLink} from "react-router-dom";
import {LinkButton} from "../components/Buttons";
import LoadingScreen from "../components/LoadingScreen";
import Header from "../components/Header";

import { connect } from "react-redux"
import { bindActionCreators} from 'redux'
import { loadWorkspaces } from "../store/actions/actions.js"

class Workspaces extends Component {

    componentDidMount () {
        this.props.loadWorkspaces(this.props.authToken);
    }

    render() {

        if (this.props.workspacesLoaded === false || this.props.workspaces === null) {
            return <LoadingScreen/>;
        }

        if (this.props.location.search === '?forward' && this.props.workspaces.length === 1) {
            return <Redirect to={`/workspace/${this.props.workspaces[0].id}/dashboard`} />;
        }

        if (this.props.workspaces.length === 0) {
            return <Redirect to='/workspace/create' />;
        }

        const workspaceList = this.props.workspaces.map(function (workspace) {
            return <div key={workspace.id}>
                <NavLink to={'/workspace/' + workspace.id + '/dashboard'} className="mx-4 bg-grey-lightest shadow my-8 text-left rounded cursor-pointer no-underline block">
                    <button className="font-normal p-8 block w-full no-underline font-medium text-blue hover:text-blue-light text-left">
                        {workspace.name}
                    </button>
                </NavLink>
            </div>
        });

        return (
            <div>
                <Header/>

                <div className="container mx-auto max-w-md">

                    {workspaceList}

                    <hr/>

                    <div className="pt-8 mb-8 pb-8">
                        <div className="text-right mr-4">
                            <NavLink to={'/workspace/create'}>
                                <LinkButton>
                                    New workspace +
                                </LinkButton>
                            </NavLink>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        workspaces: state.workspaces,
        workspacesLoaded: state.workspacesLoaded,
        authToken: state.authToken,
    }
}

const mapActionsToProps = (dispatch)  => {
    return bindActionCreators({ loadWorkspaces }, dispatch);
}

export default connect(mapStateToProps, mapActionsToProps)(Workspaces);
