import React from "react";
import DonutChart from "./DonutChart";
import {Analyst} from "../services/Analyst";

class UptimeDoughnutChart extends React.Component {
    render() {
        let options = {
            rotation: 1 * Math.PI,
            circumference: 1 * Math.PI,
            tooltips: {
                enabled: false
            },
            legend: false
        };

        const data = {
            datasets: [
                {
                    data: [
                        Analyst.getHealthyPercent(this.props.checks),
                        Analyst.getUnhealthyPercent(this.props.checks),
                    ],
                    backgroundColor: [
                        '#a2f5bf',
                        '#f9acaa'
                    ]
                },
            ],
            labels: [
                'Uptime',
                'Downtime'
            ]
        };

        return (
            <div className="p-4">
                <DonutChart data={data} options={options} height={this.props.height} />
            </div>
        );
    }
}

export default UptimeDoughnutChart;
