import React, { Component } from "react";

export class TextInput extends Component {
    render() {
        let classes = [
            'shadow',
            'appearance-none',
            'border',
            'rounded',
            'w-full',
            'py-2',
            'px-3',
            'text-grey-darker',
            'leading-loose'
        ].join(' ');

        classes += " " + this.props.className;

        return (
            <input onChange={this.props.onChange}
                   value={this.props.value}
                   name={this.props.name ? this.props.name : ''}
                   type={this.props.type ? this.props.type : 'text'}
                   className={classes}
                   required={!!this.props.required}
                   disabled={!!this.props.disabled}
                   readOnly={!!this.props.readOnly}
                   min={this.props.min}
                   max={this.props.max}
                   placeholder={this.props.placeholder ? this.props.placeholder : ''}
            />
        )
    }
}
