import React from "react";
import { Doughnut } from 'react-chartjs-2';

class DonutChart extends React.Component {
    render () {
        return (
            <Doughnut height={this.props.height} options={this.props.options} data={this.props.data} />
        );
    }
}

export default DonutChart;
