import React, { Component } from "react";

export class PrimaryButton extends Component {
    render() {

        if (this.props.disabled === true) {
            return (
                <DisabledButton>{this.props.children}</DisabledButton>
            )
        }

        let classes = [
            'bg-blue',
            'hover:bg-blue-light',
            'text-white',
            'font-bold',
            'py-4',
            'px-6',
            'border-b-4',
            'border-blue-dark',
            'hover:border-blue',
            'rounded',
            'no-underline'
        ].join(' ');

        if (this.props.className) {
            classes += " " + this.props.className
        }

        return (
            <button
                type={this.props.type}
              className={classes}
              onClick={this.props.click}>
                {this.props.children}
            </button>
        )
    }
}

export class SecondaryButton extends Component {
    render() {

        if (this.props.disabled === true) {
            return (
                <DisabledButton>{this.props.children}</DisabledButton>
            )
        }

        return (
            <button type={this.props.type}
                    className={"bg-grey-dark hover:bg-grey text-white font-bold py-4 px-6 border-b-4 border-grey-darker hover:border-grey rounded no-underline " + this.props.className}
                    onClick={this.props.onClick}>
                {this.props.children}
            </button>
        )
    }
}

export class DangerButton extends Component {
    render() {

        if (this.props.disabled === true) {
            return (
                <DisabledButton>{this.props.children}</DisabledButton>
            )
        }

        return (
            <button type={this.props.type}
                    className={"bg-red-dark hover:bg-red text-white font-bold py-4 px-6 border-b-4 border-red-darker hover:border-red rounded no-underline " + this.props.className}
                    onClick={this.props.onClick}>
                {this.props.children}
            </button>
        )
    }
}

export class LinkButton extends Component {
    render() {

        let classes = "bg-grey-light text-grey-darkest hover:text-grey-dark font-bold p-4 px-6 border-b-4 border-grey rounded no-underline ";

        if (this.props.className) {
            classes += " " + this.props.className;
        }

        return (
            <button className={classes} onClick={this.props.click} type={this.props.type}>
                {this.props.children}
            </button>
        )
    }
}

export class DisabledButton extends Component {
    render() {

        let classes = "bg-grey-light text-grey font-bold py-4 px-6 border-b-4 border-grey rounded no-underline cursor-not-allowed";

        if (this.props.className) {
            classes += " " + this.props.className;
        }

        return (
            <button disabled={true} className={classes}>
                {this.props.children}
            </button>
        )
    }
}
