import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class NotificationRule extends Component {
  render () {
    return <div className="bg-grey-lightest rounded-lg shadow mb-4">
      <div className="flex">
        <div className="w-3/4 text-left whitespace-no-wrap overflow-hidden" style={{textOverflow: 'ellipsis'}} title={this.props.rule.details.email_address}>
          <div className="p-2 m-1 text-grey-darker">
            <FontAwesomeIcon icon="envelope" className="text-grey-dark mr-8 ml-2"/>
            {this.props.rule.details.email_address}
          </div>
        </div>
        <div className="w-1/4 text-right">
          <div className="p-1 m-1 mr-2">
            <button className="text-grey-darker hover:text-grey-darkest hover:bg-grey-light bg-grey-lighter py-1 px-2 rounded-lg" onClick={() => this.props.deleteRule(this.props.rule.id)}>
              <FontAwesomeIcon icon="times"/>
            </button>
          </div>
        </div>
      </div>
    </div>
  }
}

export default NotificationRule;
