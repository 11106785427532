import React, { Component } from "react";
import config from "../services/Config";
import {NavLink, Redirect} from "react-router-dom";
import {LinkButton, PrimaryButton } from "../components/Buttons";
import {TextInput} from "../components/FormInput";
import Header from "../components/Header";
import Footer from '../components/Footer'

class WorkspaceCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            mode: 'form'
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit= this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        var c = this;

        fetch(config.API_V1_BASE_URI + '/api/v1/workspace', {
            method: 'POST',
            headers: {
                'X-Auth': this.props.auth.getToken(),
                'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
            },
            body: 'name='+this.state.name
        }).then(function (response) {
            if (response.status === 200) {
                c.setState({mode: 'success'});
                return;
            }
            if (response.status === 401) {
                c.props.auth.logout();
                return;
            }
            alert('Sorry, something went wrong');
        });
    }

    render() {

        if (this.state.mode === 'success') {
            return (
                <Redirect to={'/workspaces'} />
            )
        }

        return (
            <div>

                <Header/>

                <div className="container max-w-lg mx-auto my-8 px-4">

                    <h1 className="text-3xl font-light text-center py-8">Create a new workspace</h1>

                    <p className="text-grey py-8">
                        A workspace is a container for all of your checks and monitors within Whoops.
                        When you've created your workspace, you can share it with other.
                    </p>

                    <form onSubmit={this.handleSubmit}>
                        <div className="md:flex">
                            <div className="md:w-7/10 mb-4">
                                <TextInput onChange={this.handleChange} value={this.state.name} name="name" placeholder="My workspace" required={true} />
                            </div>
                            <div className="md:w-3/10">
                                <div className="md:pl-4">
                                    <PrimaryButton className="block w-full">Create</PrimaryButton>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="pt-4 md:pt-0 pb-8">
                        <NavLink to="/workspaces" className="no-underline md:text-right">
                            <LinkButton className="block w-full md:inline md:w-auto md:float-right">Cancel</LinkButton>
                        </NavLink>
                    </div>
                </div>

                <div><br/><br/><br/><br/></div>

                <Footer/>
            </div>
        );
    }
}

export default WorkspaceCreate;
